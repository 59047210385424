import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { getConfigurationValue, getMssEmail } from 'dmpconnectjsapp-base/helpers/accessors';
import { toast } from 'react-toastify';
import { setUserConfiguration } from 'dmpconnectjsapp-base/actions';
import ButtonWithLoader from '../Common/Form/ButtonWithLoader';
import { setPersistedAppConfiguration } from '../../dmpconnect/actions';
import checkAccessRights from '../AccessRights/CheckAccessRights';
import CustomCheckbox from '../Common/Form/Input/CustomCheckbox';
import { otpChannelTypes } from '../../dmpconnect/constants/dmpConstants';
import { getMssSenderWording } from '../../dmpconnect/helpers/mss';

function MssConfigOTP({
  dispatch, otpChannelType, mssEmail, accessRights,
  mssSenderWording, mssReplyTo, onSubmit, mssActive,
  advancedContent,
}) {
  const [email, setEmail] = React.useState(mssEmail);
  const [senderWording, setSenderWording] = React.useState(mssSenderWording);
  const [channelType, setChannelType] = React.useState(otpChannelType || otpChannelTypes.MOBILE);
  const [submitting, setSubmitting] = React.useState(false);

  const submitMss = () => {
    setSubmitting(true);

    onSubmit();

    dispatch(setPersistedAppConfiguration('mssOtpChannelType', channelType));
    dispatch(setUserConfiguration(accessRights.psId, {
      mssEmail: email,
      mssSenderWording: senderWording,
      mssReplyTo,
    }));
    toast(
      'La configuration MSS à été mise à jour',
      {
        type: 'success',
        position: 'top-right',
        closeOnClick: true,
        autoClose: true,
        hideProgressBar: true,
      },
    );
    setSubmitting(false);
  };

  return (
    <>
      <h6 className="mt-3">OTP</h6>
      <Form.Group>
        <Form.Label>
          Email
        </Form.Label>
        <Form.Control
          id="mssEmail"
          disabled={!mssActive}
          onChange={event => setEmail(event.target.value)}
          name="mssEmail"
          value={email}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          Nom de l&apos;expéditeur
        </Form.Label>
        <Form.Control
          id="mssSenderWording"
          disabled={!mssActive}
          onChange={event => setSenderWording(event.target.value)}
          name="mssSenderWording"
          value={senderWording}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          Type de canal préféré pour recevoir le code de vérification
        </Form.Label>
        <div className="d-flex flex-row align-items-center">
          <div className="toggle-checkbox-container mr-2">
            <div className={`toggle-checkbox border ${channelType === otpChannelTypes.MOBILE ? 'active' : ''}`}>
              <CustomCheckbox
                type="radio"
                id="mss-otp-mobile"
                checked={channelType === otpChannelTypes.MOBILE}
                label="SMS"
                value={otpChannelTypes.MOBILE}
                name="otpChannelType"
                onChange={() => setChannelType(otpChannelTypes.MOBILE)}
                disabled={!mssActive}
              />
            </div>
          </div>

          <div className="toggle-checkbox-container">
            <div className={`toggle-checkbox border ${channelType === otpChannelTypes.MAIL ? 'active' : ''}`}>
              <CustomCheckbox
                type="radio"
                id="mss-otp-email"
                checked={channelType === otpChannelTypes.MAIL}
                label="Email"
                value={otpChannelTypes.MAIL}
                name="otpChannelType"
                onChange={() => setChannelType(otpChannelTypes.MAIL)}
                disabled={!mssActive}
              />
            </div>
          </div>
        </div>
        {/* <Form.Control.Feedback type="invalid"> */}
        {/*  {errors.mssEmail} */}
        {/* </Form.Control.Feedback> */}
      </Form.Group>

      {advancedContent}

      <div className="admin-content-divider" />
      <Form.Group>
        <ButtonWithLoader
          label="Valider"
          loading={submitting}
          onClick={() => submitMss()}
          disabled={!mssActive}
        />
      </Form.Group>
    </>
  );
}

MssConfigOTP.propTypes = {
  dispatch: PropTypes.func.isRequired,
  mssEmail: PropTypes.string,
  mssSenderWording: PropTypes.string,
  mssReplyTo: PropTypes.string,
  mssActive: PropTypes.bool,
  otpChannelType: PropTypes.number,
  accessRights: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  advancedContent: PropTypes.node,
};
MssConfigOTP.defaultProps = {
  mssActive: false,
  mssEmail: '',
  mssSenderWording: '',
  mssReplyTo: '',
  otpChannelType: otpChannelTypes.MOBILE,
  advancedContent: undefined,
};

function mapStateToProps(state) {
  const {
    dmpConnectPersistedAppConfiguration,
  } = state;
  return {
    mssEmail: getMssEmail(state),
    mssSenderWording: getMssSenderWording(state),
    otpChannelType: getConfigurationValue('mssOtpChannelType', dmpConnectPersistedAppConfiguration),
  };
}

export default checkAccessRights(connect(mapStateToProps)(MssConfigOTP));
