import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  dmpconnect,
  dmpconnectCPxConfiguration,
  dmpconnectInit,
  getDmpconnectConnectorConfigReducer,
  getDmpconnectDpConfigReducer,
  getDmpconnectEsConfigReducer,
  getDmpconnectTSEConfigReducer,
  getDmpconnectINSiConfigReducer,
  getDmpconnectALDiConfigReducer,
  dmpconnectMonitoring,
  dmpconnectInteropCodes,
} from 'dmpconnectjsapp-base/reducers';
import getDmpconnectApCvConfigReducer from 'dmpconnectjsapp-base/reducers/dmpconnectApCvConfiguration';
import getDmpconnectPersistedConnectorConfiguration
  from 'dmpconnectjsapp-base/reducers/dmpconnectPersistedConnectorConfiguration';
import { apiSections, mssReceiptNotificationTypes } from 'dmpconnectjsapp-base/constants';
import { mssSubTypes } from 'dmpconnectjsapp-base/actions/config/commands';
import getDmpconnectMSSConfigReducer from 'dmpconnectjsapp-base/reducers/dmpconnectMSSConfiguration';
import {
  dmpconnectAccessibleDmpList,
  dmpconnectApplication,
  dmpconnectDocumentsCache,
  dmpconnectPDFs,
  dmpConnectPersistedAppConfiguration,
  dmpconnectRemote,
  dmpconnectUser,
  dmpconnectVirtualPrinter,
} from '../dmpconnect/reducers';
import env from '../envVariables';
import {
  aldConfigFromEnv, apCvConfigFromEnv,
  connectorConfigFromEnv,
  dpConfigFromEnv,
  esConfigFromEnv, insiConfigFromEnv, mssConfigFromEnv, persistedConnectorConfigFromEnv,
  tseConfigFromEnv,
} from './configFromEnv';
import { dmpconnectFilters } from '../dmpconnect/reducers/dmpconnectFilters';
import { news } from '../dmpconnect/reducers/news';
import { dmpLandingPages } from '../dmpconnect/constants';
import { openIDConfiguration } from '../dmpconnect/reducers/openIDConfiguration';
import { mssMessages } from '../dmpconnect/reducers/mssMessages';
import { esRestPersistantData } from '../dmpconnect/reducers/esRestPersistantData';
import { userPreferences } from '../dmpconnect/reducers/userPreferences';
import { mssOperatorsConfig } from '../mssOperatorsConfig';
import { mssPatientInfos } from '../dmpconnect/reducers/mssPatientInfos';

export const storages = [];
function customPersistReducer(config, reducer) {
  storages.push(config.key);
  return persistReducer(config, reducer);
}

const dmpconnectConnectorConfig = getDmpconnectConnectorConfigReducer(connectorConfigFromEnv);
const dmpconnectPersistedConnectorConfig = getDmpconnectPersistedConnectorConfiguration(persistedConnectorConfigFromEnv);
const dmpconnectESConfiguration = getDmpconnectEsConfigReducer(esConfigFromEnv);
const dmpconnectTSEConfiguration = getDmpconnectTSEConfigReducer(tseConfigFromEnv);
const dmpconnectDpConfiguration = getDmpconnectDpConfigReducer(dpConfigFromEnv);
const dmpconnectINSiConfiguration = getDmpconnectINSiConfigReducer(insiConfigFromEnv);
const dmpconnectALDiConfiguration = getDmpconnectALDiConfigReducer(aldConfigFromEnv);
const dmpconnectApCvConfiguration = getDmpconnectApCvConfigReducer(apCvConfigFromEnv);
const dmpconnectMSSConfiguration = getDmpconnectMSSConfigReducer(mssConfigFromEnv());

const rootPersistConfig = {
  key: 'efficience_root',
  storage,
  whitelist: [
    'news',
    'dmpconnectUser',
    'dmpconnectCPxConfiguration',
    'dmpConnectPersistedAppConfiguration',
    'userPreferences',
    'dmpconnectPersistedConnectorConfiguration',
    'dmpconnectTSEConfiguration',
  ],
  version: 28,
  migrate: createMigrate(
    {
      // migration from version 1 to version 2
      2: state => ({
        ...state,
        dmpconnectINSiConfiguration: { ...insiConfigFromEnv },
        dmpconnectALDiConfiguration: { ...aldConfigFromEnv },
      }),
      3: state => ({
        ...state,
        production_mode: Number(env.REACT_APP_PRODUCTON_MODE) === 1,
      }),
      4: state => ({
        ...state,
        dmpconnectApCvConfiguration: { ...apCvConfigFromEnv },
      }),
      5: state => ({
        ...state,
        dmpconnectPersistedConnectorConfiguration: {
          ...state.dmpconnectPersistedConnectorConfiguration,
          nfcCardReader: -1,
        },
      }),
      // remove services config from general state
      7: (state) => {
        const {
          dmpconnectINSiConfiguration: insi,
          dmpconnectALDiConfiguration: aldi,
          dmpconnectApCvConfiguration: apcv,
          dmpconnectDpConfiguration: dp,
          ...newState
        } = state;
        return { ...newState };
      },
      8: state => ({
        ...state,
        dmpconnectConnectorConfig: {
          ...state.dmpconnectConnectorConfig,
          applicationId: connectorConfigFromEnv.applicationId,
        },
      }),
      9: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          dmpLandingPage: env.REACT_APP_DMP_LANDING_PAGE || dmpLandingPages.PARCOURS_SOIN,
        },
      }),
      10: state => ({
        ...state,
        dmpconnectConnectorConfig: {
          ...state.dmpconnectConnectorConfig,
          applicationId: env.REACT_APP_ID,
        },
      }),
      11: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          cpxLoginType: 'CPX',
        },
      }),
      12: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          cpxLoginToken: null,
        },
      }),
      13: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          vitaleAutoRead: Number(env.REACT_APP_VITALE_AUTO_READ) === 1,
        },
      }),
      14: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          nfcReaderActive: true,
          qrCodeReaderActive: true,
        },
      }),
      15: (state) => {
        const {
          dmpConnectPersistedAppConfiguration: {
            nfcReaderActive,
            qrCodeReaderActive,
            ...newPersistedAppState
          },
          ...newState
        } = state;
        return {
          dmpConnectPersistedAppConfiguration: {
            ...newPersistedAppState,
          },
          ...newState,
        };
      },
      // fix
      16: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          cpxLoginToken: null,
          cpxLoginType: 'CPX',
          vitaleAutoRead: Number(env.REACT_APP_VITALE_AUTO_READ) === 1,
        },
      }),
      17: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          hideConfig: Number(env.REACT_APP_HIDE_CONFIG) === 1,
          hideMailiz: Number(env.REACT_APP_HIDE_MAILIZ) === 1,
          noDashboard: Number(env.REACT_APP_DEACTIVATE_DASHBOARD) === 1,
          mssReceiptNotificationType: mssReceiptNotificationTypes[env.REACT_APP_MSS_RECEIPT_NOTIFICATION_TYPE],
          hideNewsSidebar: Number(env.REACT_APP_HIDE_NEWS_SIDEBAR) === 1,
        },
      }),
      18: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          mssApiType: env.REACT_APP_MSS_API_TYPE || mssSubTypes.IMAP,
        },
      }),
      19: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          ignorePdfA1Transparency: Number(env.REACT_APP_PDFA1_CONVERSION_IGNORE_TRANSPARENCY) === 1,
          disabledPdfA1Conversion: Number(env.REACT_APP_PDFA1_CONVERSION_DISABLE) === 1,
        },
      }),
      20: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          remoteControlDisableExports: Number(env.REACT_APP_REMOTE_CONTROL_DISABLE_EXPORTS) === 1,
        },
      }),
      21: state => ({
        ...state,
        dmpconnectConnectorConfig: {
          ...state.dmpconnectConnectorConfig,
          webPsRootUrl: env.REACT_APP_WEB_PS_BASE_URL,
        },
      }),
      22: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          mssApiType: env.REACT_APP_MSS_API_TYPE,
        },
      }),
      23: state => ({
        ...state,
        userPreferences: {
          lastChangeDateTime: state.dmpConnectPersistedAppConfiguration.lastChangeDateTime,
          changeInterval: state.dmpConnectPersistedAppConfiguration.changeInterval,
          sidebarIsOpened: state.dmpConnectPersistedAppConfiguration.sidebarIsOpened,
          hrVsmAutoOpen: state.dmpConnectPersistedAppConfiguration.hrVsmAutoOpen,
          minimumMinorAge: state.dmpConnectPersistedAppConfiguration.minimumMinorAge,
          mssActive: state.dmpConnectPersistedAppConfiguration.mssActive,
          dmpLandingPage: state.dmpConnectPersistedAppConfiguration.dmpLandingPage,
        },
      }),
      24: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          vidalAppKey: env.REACT_APP_VIDAL_APP_KEY,
          vidalAppId: env.REACT_APP_VIDAL_APP_ID,
          vidalUrl: env.REACT_APP_VIDAL_API,
          vidalActive: Number(env.REACT_APP_VIDAL) === 1,
        },
      }),
      25: state => ({
        ...state,
        dmpconnectConnectorConfig: {
          ...state.dmpconnectConnectorConfig,
          sessionTimeout: Number(env.REACT_APP_SESSION_TIMEOUT),
        },
      }),
      26: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          dmpConnectLogLevel: Number(env.REACT_APP_DEFAULT_DMPC_LOG_LEVEL),
          dmpConnectJsLogLevel: Number(env.REACT_APP_DEFAULT_DMPCJS_LOG_LEVEL),
        },
      }),
      27: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          enableCustomPracticeLocation: Number(env.REACT_APP_ENABLE_CUSTOM_PRACTICE_SETTING) === 1,
        },
      }),
      28: state => ({
        ...state,
        dmpConnectPersistedAppConfiguration: {
          ...state.dmpConnectPersistedAppConfiguration,
          isMobileLayout: Number(env.REACT_APP_MOBILE) === 1,
        },
      }),
    },
    { debug: true },
  ),
};

if (Number(env.REACT_APP_DEBUG) === 1) {
  rootPersistConfig.whitelist.push('dmpconnectConnectorConfig');
}

const dmpconnectPersistConfig = {
  key: 'efficience_commands',
  storage,
  version: 1,
  blacklist: [
    apiSections.FIND_DOCUMENTS_SECTION,
    apiSections.DOCUMENT_CONTENT_SECTION,
    apiSections.MSS_DOWNLOAD_ATTACHMENT,
    apiSections.MSS_GET_MESSAGE_CONTENT,
    apiSections.GET_DOCS_FROM_IHE_XDM,
    apiSections.GET_LOG_TAIL,
    apiSections.GET_SESSION_LOG_TAIL,
    apiSections.GET_SYSTEM_INFO,
    apiSections.REMOTE_SEND_DOCUMENT,
    apiSections.SEND_DOCUMENT_SECTION,
    apiSections.MSS_SEND_SMTP_EMAIL,
    apiSections.MSS_GENERATE_ATTACHMENTS,
    apiSections.MSS_SEARCH_MESSAGES,
    apiSections.MSS_SYNC_MESSAGES,
    apiSections.MSS_FULL_SEARCH_MESSAGES,
    apiSections.MSS_SEND_SMTP_EMAIL,
    apiSections.GET_PERSISTANT_DATA,
    apiSections.SET_PERSISTANT_DATA,
  ],
};
const dmpconnectESPersistConfig = {
  key: 'efficience_es_config',
  storage,
  version: 7,
  migrate: createMigrate({
    2: state => ({
      ...state,
      hpAuthenticationContext: esConfigFromEnv.hpAuthenticationContext,
      hpAuthenticationMode: esConfigFromEnv.hpAuthenticationMode,
    }),
    3: state => ({
      ...state,
      loginApiExtraHeaders: env.REACT_APP_ES_LOGIN_API_EXTRA_HEADER,
      loginCheckApi: Number(env.REACT_APP_ES_LOGIN_CHECK_API) === 1,
      loginCheckApiEndpoint: env.REACT_APP_ES_LOGIN_CHECK_API_ENDPOINT,
      loginCheckApiPSParam: env.REACT_APP_ES_LOGIN_CHECK_API_ENDPOINT_PS_PARAM,
      loginCheckApiPatientParam: env.REACT_APP_ES_LOGIN_CHECK_API_ENDPOINT_PATIENT_PARAM,
    }),
    4: state => ({
      ...state,
      es_id_mss: env.REACT_APP_ES_ID_MSS,
    }),
    5: state => ({
      ...state,
      esMssCertificate: env.REACT_APP_ES_CERT_MSS,
    }),
    6: state => ({
      ...state,
      es_id_insi: env.REACT_APP_ES_ID_INSI || null,
    }),
    7: state => ({
      ...state,
      esMssImapCertificate: env.REACT_APP_MSS_IMAP_CERT || null,
      esMssSmtpCertificate: env.REACT_APP_MSS_SMTP_CERT || null,
      esMssWsCertificate: env.REACT_APP_MSS_WS_CERT || null,
    }),
  }),
};
const dmpconnectPDFsPersistConfig = {
  key: 'efficience_PDFs',
  storage,
  version: 1,
};
const dmpconnectAccessibleDmpListPersistConfig = {
  key: 'efficience_td04',
  storage,
  version: 1,
};
const dmpconnectDocumentsCachePersistConfig = {
  key: 'efficience_documents',
  storage,
  version: 1,
};
const dmpconnectFiltersPersistConfig = {
  key: 'efficience_filters',
  storage,
  version: 1,
};
const dmpconnectINSiConfigurationPersistConfig = {
  key: 'efficience_insi_config',
  storage,
  version: 1,
};
const dmpconnectALDiConfigurationPersistConfig = {
  key: 'efficience_aldi_config',
  storage,
  version: 1,
};
const dmpconnectApCvConfigurationPersistConfig = {
  key: 'efficience_apcv_config',
  storage,
  version: 3,
  migrate: createMigrate({
    2: state => ({
      ...state,
      startDate: env.REACT_APP_APCV_START_DATE,
    }),
    3: state => ({
      ...state,
      nfcReaderActive: true,
      qrCodeReaderActive: true,
    }),
  }),
};
const dmpconnectDpConfigurationPersistConfig = {
  key: 'efficience_dp_config',
  storage,
  version: 1,
};
const openIDPersistConfig = {
  key: 'efficience_openid_config',
  storage,
  version: 2,
  migrate: createMigrate({
    2: state => ({
      ...state,
      REACT_APP_ES_LOGIN_OPENID_AUTH_ENDPOINT:
        state.REACT_APP_ES_LOGIN_OPENID_AUTH_ENDPOINT === 'https://wallet.bas.esw.esante.gouv.fr/auth'
          ? 'https://wallet.bas.psc.esante.gouv.fr/auth'
          : state.REACT_APP_ES_LOGIN_OPENID_AUTH_ENDPOINT,
      REACT_APP_ES_LOGIN_OPENID_JWKS_ENDPOINT:
        state.REACT_APP_ES_LOGIN_OPENID_JWKS_ENDPOINT === 'https://auth.bas.esw.esante.gouv.fr/auth/realms/esante-wallet/protocol/openid-connect/certs'
          ? 'https://auth.bas.psc.esante.gouv.fr/auth/realms/esante-wallet/protocol/openid-connect/certs'
          : state.REACT_APP_ES_LOGIN_OPENID_JWKS_ENDPOINT,
      REACT_APP_ES_LOGIN_OPENID_ENDSESSION_ENDPOINT:
        state.REACT_APP_ES_LOGIN_OPENID_ENDSESSION_ENDPOINT === 'https://auth.bas.esw.esante.gouv.fr/auth/realms/esante-wallet/protocol/openid-connect/logout'
          ? 'https://auth.bas.psc.esante.gouv.fr/auth/realms/esante-wallet/protocol/openid-connect/logout'
          : state.REACT_APP_ES_LOGIN_OPENID_ENDSESSION_ENDPOINT,
    }),
  }),
};
const mssMessagesPersisConfig = {
  key: 'efficience_mss_messages',
  storage,
  version: 2,
  migrate: createMigrate({
    2: state => ({
      mssApiType: state.mssApiType,
    }),
  }),
};
const mssPatientInfosPersistConfig = {
  key: 'efficience_mss_patient_infos',
  storage,
};
const dmpconnectMSSConfigurationPersistConfig = {
  key: 'efficience_mss_config',
  storage,
  version: 10,
  migrate: createMigrate({
    2: state => ({
      ...state,
      mssOperatorsConfig,
    }),
    7: state => ({
      ...state,
      ...mssConfigFromEnv(),
    }),
    8: (state) => {
      const {
        mssImapLoginPasswd = '',
        mssSmtpLoginPasswd = '',
      } = state;

      const [imapLogin, imapPasswd, imapSaslLogin] = mssImapLoginPasswd.split(':');
      const [smtpLogin, smtpPasswd, smtpSaslLogin] = mssSmtpLoginPasswd.split(':');
      return {
        ...state,
        mssImapLogin: imapLogin,
        mssImapPasswd: imapPasswd,
        mssImapSaslLogin: imapSaslLogin,
        mssSmtpLogin: smtpLogin,
        mssSmtpPasswd: smtpPasswd,
        mssSmtpSaslLogin: smtpSaslLogin,
      };
    },
    9: state => ({
      ...state,
      ...mssConfigFromEnv(),
    }),
    10: (state) => {
      const {
        mssImapLoginPasswd = '',
        mssSmtpLoginPasswd = '',
      } = state;

      const [imapLogin, imapPasswd, imapSaslLogin] = mssImapLoginPasswd.split(':');
      const [smtpLogin, smtpPasswd, smtpSaslLogin] = mssSmtpLoginPasswd.split(':');
      return {
        ...state,
        mssImapLogin: imapLogin || '',
        mssImapPasswd: imapPasswd || '',
        mssImapSaslLogin: imapSaslLogin || '',
        mssSmtpLogin: smtpLogin || '',
        mssSmtpPasswd: smtpPasswd || '',
        mssSmtpSaslLogin: smtpSaslLogin || '',
      };
    },
  }),
};
const esRestPersistantDataPersistConfig = {
  key: 'efficience_es-rest_persistant_data',
  storage,
};
const interopCodesPersistConfig = {
  key: 'efficience_interop_codes',
  storage,
};


const createRootReducer = (history) => {
  if (Number(env.REACT_APP_ENABLE_CUSTOM_PRACTICE_SETTING) === 1) {
    localStorage.setItem('enableCustomPracticeLocation', 1);
  }

  return combineReducers({
    router: connectRouter(history),

    // external connector
    dmpconnect: customPersistReducer(dmpconnectPersistConfig, dmpconnect),
    dmpconnectInit,

    dmpconnectConnectorConfig,
    dmpconnectCPxConfiguration,
    dmpconnectESConfiguration: customPersistReducer(dmpconnectESPersistConfig, dmpconnectESConfiguration),
    dmpconnectTSEConfiguration,
    dmpconnectPersistedConnectorConfiguration: dmpconnectPersistedConnectorConfig,
    dmpconnectInteropCodes: customPersistReducer(interopCodesPersistConfig, dmpconnectInteropCodes),

    // services
    dmpconnectINSiConfiguration: Number(env.REACT_APP_DEBUG) === 1
      ? customPersistReducer(dmpconnectINSiConfigurationPersistConfig, dmpconnectINSiConfiguration)
      : dmpconnectINSiConfiguration,
    dmpconnectALDiConfiguration: Number(env.REACT_APP_DEBUG) === 1
      ? customPersistReducer(dmpconnectALDiConfigurationPersistConfig, dmpconnectALDiConfiguration)
      : dmpconnectALDiConfiguration,
    dmpconnectApCvConfiguration: Number(env.REACT_APP_DEBUG) === 1
      ? customPersistReducer(dmpconnectApCvConfigurationPersistConfig, dmpconnectApCvConfiguration)
      : dmpconnectApCvConfiguration,
    dmpconnectDpConfiguration: customPersistReducer(dmpconnectDpConfigurationPersistConfig, dmpconnectDpConfiguration),
    dmpconnectMSSConfiguration: customPersistReducer(dmpconnectMSSConfigurationPersistConfig, dmpconnectMSSConfiguration),

    // application
    esRestPersistantData: customPersistReducer(esRestPersistantDataPersistConfig, esRestPersistantData),
    news,
    dmpconnectUser,
    dmpconnectFilters: customPersistReducer(dmpconnectFiltersPersistConfig, dmpconnectFilters),
    dmpconnectMonitoring,
    dmpconnectApplication,
    dmpConnectPersistedAppConfiguration,
    userPreferences,
    dmpconnectRemote,
    dmpconnectVirtualPrinter,
    // dmpconnectPDFs,
    dmpconnectPDFs: customPersistReducer(dmpconnectPDFsPersistConfig, dmpconnectPDFs),
    dmpconnectAccessibleDmpList: customPersistReducer(dmpconnectAccessibleDmpListPersistConfig, dmpconnectAccessibleDmpList),
    dmpconnectDocumentsCache: customPersistReducer(dmpconnectDocumentsCachePersistConfig, dmpconnectDocumentsCache),
    openIDConfiguration: customPersistReducer(openIDPersistConfig, openIDConfiguration),


    // mss
    mssMessages: customPersistReducer(mssMessagesPersisConfig, mssMessages),
    mssPatientInfos: customPersistReducer(mssPatientInfosPersistConfig, mssPatientInfos),
  });
};

export default history => customPersistReducer(rootPersistConfig, createRootReducer(history));
