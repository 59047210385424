import React from 'react';
import * as yup from 'yup';
import {
  call, put, select, take,
} from 'redux-saga/effects';
import { LOCATION_CHANGE, push, replace } from 'connected-react-router';
import { softwareErrors } from 'dmpconnectjsapp-base/errors';
import {
  clearModalError,
  clearSection,
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType,
  logoutSuccess, resetMssConfiguration, setDpConfiguration,
  setESConfiguration, setGlobalConfiguration,
  setPersistedConnectorConfiguration, setUserConfiguration,
} from 'dmpconnectjsapp-base/actions';
import { apiSections, mssLoginTypes } from 'dmpconnectjsapp-base/constants';
import commands, { mssSubTypes } from 'dmpconnectjsapp-base/actions/config/commands';
import { formatFindPatientsParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { encodeIns } from 'dmpconnectjsapp-base/utils/insUtils';
import {
  getApiType,
  getConfigurationValue,
  getCurrentPathname, getDmpconnectPersistedConnectorConfig,
} from 'dmpconnectjsapp-base/helpers/accessors';
import { getAccessRightsProps, isTransactionAllowed, transactions } from 'dmpconnectjsapp-base/rules/accessRights';
import { esLoginTypes } from 'dmpconnectjsapp-base/reducers/dmpconnectESConfiguration';
import { toast } from 'react-toastify';
import moment from 'moment';
import { generateAndSaveToken } from 'dmpconnectjsapp-base/sagas/utilsSagas';
import base64 from 'base-64';
import mergeWith from 'lodash.mergewith';
import { ValidationError } from 'yup';
import { hasError, isLoading, isReady } from 'dmpconnectjsapp-base/helpers/common';
import { checkIfUserIsLoggedIn } from './securitySagas';
import {
  b64DecodeUnicode, isUrlValid, mergeSearchAndHash, parseQueryString,
} from '../utils/dataUtils';
import {
  clearLoginReferer,
  getAction,
  getDirectAuthenticationDMPStatus, requestPcscReaders,
  resetMssEmailContent,
  selectINS,
  setApiLoginCheckValues,
  setDmpSearchPanelOpened,
  setFindPatientsIns,
  setFindPatientsParams, setForcedCpxReader, setForcedVitaleReader, setLoginTab,
  setModalError,
  setMssEmailContent,
  setPersistedAppConfiguration,
  setSaasTokenValidated,
  setShowMssPopup,
  setSubmitEngine,
  setTseConfiguration,
  setUrlProcessed,
} from '../actions';
import { createError, createErrorDetails, createModalError } from '../errors';
import { errorTypes } from '../errors/errorConfiguration';
import {
  API_TYPES,
  availableAirStrongAuthModes, dmpconnectHashes, dmpStatuses, searchPanels,
} from '../constants';
import { findPatientsDefaultValues, findPatientsParamsSchema } from '../helpers/searchUtils';
import {
  getDmpconnectESConfiguration,
  getDmpLandingPage,
  getDocumentRedirectUrl, getSaasTokenConfig, getSessionId, getUserPreferences,
  isInsValid,
} from '../helpers';
import { getAllDocsFromCache } from './utilsSagas';
import { getLastHR, getLastVSM } from '../helpers/documentUtils';
import env from '../../envVariables';
import { getEsUserAuthenticationContext, getEsUserAuthenticationMode } from '../rules/airRules';
import { getUniqueUUid } from '../rules/documentRules';
import { getMssConfigFromOperatorConfig } from '../helpers/mss';


const handleLoginCheck = function* (logincheck) {
  const requiredValues = (env.REACT_APP_ES_LOGIN_CHECK_REQUIRED_VALUES || 'name,given,rpps').split(',');
  const loginCheckOK = logincheck && requiredValues.every(val => val in logincheck);

  if (loginCheckOK) {
    yield put(setPersistedAppConfiguration('logincheck', { ...logincheck }));
  } else {
    // show error modal
    const error = createError(errorTypes.SoftwareErrors, softwareErrors.LOGINCHECK_MISSING_VALUE);
    const details = [
      createErrorDetails('provided', logincheck),
      createErrorDetails('required', { properties: requiredValues.join(', ') }),
    ];
    const modalError = createModalError(error, details);
    yield put(setModalError(modalError));
  }
};

const handleApiLoginCheck = function* (values) {
  const { psInternalId, patientInternalId } = values;

  if (psInternalId && patientInternalId) {
    yield put(setApiLoginCheckValues({ psInternalId, patientInternalId }));
  } else {
    // show error modal
    const error = createError(errorTypes.SoftwareErrors, softwareErrors.LOGINCHECK_MISSING_VALUE);
    const details = [
      createErrorDetails('provided', values),
      createErrorDetails('required', { psInternalId: 'xxxxx', patientInternalId: 'yyyyy' }),
    ];
    const modalError = createModalError(error, details);
    yield put(setModalError(modalError));
  }
};

const handleSearchByINS = function* (ins) {
  yield put(setDmpSearchPanelOpened(searchPanels.ACCESS_BY_INS_PANEL));

  yield put(push(`/home/${searchPanels.ACCESS_BY_INS_PANEL}`));

  let regexp;
  let searchedIns;
  if (ins.insC) {
    searchedIns = ins.insC;
    regexp = /^\d{22}$/;
  } else if (ins.insNir) {
    searchedIns = ins.insNir;
    regexp = /^\d{15}[a-zA-Z]?$/;
  }
  yield put(setFindPatientsIns(searchedIns));
  const valid = searchedIns && regexp && regexp.test(searchedIns);

  if (!valid) {
    const details = [createErrorDetails('Erreur détaillée', { Ins: ins })];
    const modalError = createModalError({
      i_apiErrorType: errorTypes.SoftwareErrors,
      i_apiErrorCode: ins.insC ? softwareErrors.INSC_INVALID_FORMAT : softwareErrors.INS_NIR_INVALID_FORMAT,
    }, details);

    yield put(setModalError(modalError));
  } else {
    yield put(clearSection(apiSections.INSC_TO_NIR));
    yield put(clearSection(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION));
    if (ins.insC) {
      yield put(getAction(
        commands.getInsNirFromInsC,
        apiSections.INSC_TO_NIR,
        { s_insC: ins.insC },
      ));
    } else if (ins.insNir && isInsValid(ins.insNir)) {
      yield put(getDirectAuthenticationDMPStatus(ins.insNir));
    }

    yield put(selectINS(ins.insNir));
    const directAuthResult = yield take([
      dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      dmpCommandFailureContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
    ]);

    if (
      directAuthResult
        && directAuthResult.type === dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION)
    ) {
      const {
        ExistingTestAnswer: {
          i_dmpStatus,
          AdminData: {
            Ins: {
              s_ins,
              s_insType,
            },
          },
        },
      } = directAuthResult.data;
      if (i_dmpStatus === dmpStatuses.DMPExist) {
        const { accessRights } = yield select(getAccessRightsProps);
        const dmpLandingPage = yield select(getDmpLandingPage);
        yield put(push(getDocumentRedirectUrl(accessRights, s_ins + s_insType, dmpLandingPage)));
      }
    }
  }
};

const handleSearchPatient = function* (search) {
  yield put(clearSection(apiSections.FIND_PATIENTS_SECTION));
  yield put(setDmpSearchPanelOpened(searchPanels.ACCESS_BY_SEARCH_PANEL));
  const form = mergeWith({}, findPatientsDefaultValues, search, (a, b) => (b === null || b === undefined ? a : b));
  const isFormValid = findPatientsParamsSchema.isValidSync(form, { abortEarly: false });
  yield put(setFindPatientsParams(form));

  if (isFormValid) {
    yield put(getAction(
      commands.findPatient,
      apiSections.FIND_PATIENTS_SECTION,
      formatFindPatientsParams(
        form.name,
        form.givenName,
        form.city,
        form.postalCode,
        form.birthday,
        form.sex,
        form.approxName,
        form.approxCity,
      ),
    ));
    const { data: { Patients: patients } } = yield take([
      'DMPC_COMMAND_FINDPATIENTS_SUCCESS',
      'DMPC_COMMAND_FINDPATIENTS_FAILURE',
    ]);
      // If there's only one result we redirect to the patient page
    if (patients && patients.length === 1) {
      const patient = patients[0];
      const { Ins: { s_ins: ins, s_insType: insType } } = patient;

      // we run TD0.2 transaction
      yield put(selectINS(ins + insType));
      yield put(getDirectAuthenticationDMPStatus(ins + insType));


      const directAuthResult = yield take([
        dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
        dmpCommandFailureContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      ]);

      if (
        directAuthResult
        && directAuthResult.type === dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION)
      ) {
        // we redirect to the patient page
        const { accessRights } = yield select(getAccessRightsProps);
        const dmpLandingPage = yield select(getDmpLandingPage);
        yield put(push(getDocumentRedirectUrl(accessRights, ins + insType, dmpLandingPage)));
      } else {
        yield put(push(`/home/${searchPanels.ACCESS_BY_SEARCH_PANEL}`));
      }
    } else {
      yield put(push(`/home/${searchPanels.ACCESS_BY_SEARCH_PANEL}`));
    }
  } else {
    yield put(push(`/home/${searchPanels.ACCESS_BY_SEARCH_PANEL}`));
  }
};

const handleShowVSMorRH = function* (payload) {
  const userPref = yield select(getUserPreferences);
  const hrVsmAutoOpen = yield getConfigurationValue('hrVsmAutoOpen', userPref);
  const { accessRights } = yield select(getAccessRightsProps);

  if (
    isTransactionAllowed(accessRights, transactions.FIND_DOCUMENTS_META)
    && hrVsmAutoOpen
    && (
      payload.location.pathname.endsWith('documents')
    && payload.location.hash === dmpconnectHashes.REDIRECT_TO_LAST_VSM_OR)
  ) {
    const { command: { s_ins } } = yield take(dmpCommandSuccessContextualizedType(apiSections.FIND_DOCUMENTS_SECTION));

    const pathname = yield select(getCurrentPathname);
    // meanwhile the user may have move back to the dashboard
    // TODO handle command aborting when unmounting component
    const lastNewDocs = yield select(getAllDocsFromCache, s_ins);
    if (pathname.endsWith('documents')) {
      const vsm = yield getLastVSM(lastNewDocs);
      const hr = yield getLastHR(lastNewDocs);
      yield put(replace(`/dmp/${encodeIns(s_ins)}/documents`));
      if (vsm) {
        const { s_uniqueId, s_uuid } = vsm;
        yield put(push(`/dmp/${encodeIns(s_ins)}/document/${encodeIns(getUniqueUUid(s_uniqueId, s_uuid))}`));
      } else if (hr) {
        const { s_uniqueId, s_uuid } = hr;
        yield put(push(`/dmp/${encodeIns(s_ins)}/document/${encodeIns(getUniqueUUid(s_uniqueId, s_uuid))}`));
      }
    }
  }
};

const handleSearchFromUrl = function* (ins, search, pathname) {
  if (ins) {
    yield call(handleSearchByINS, ins);
  } else if (search) {
    yield call(handleSearchPatient, { ...search, approxCity: !!search.approxCity, approxName: !!search.approxName });
  }
};

const handleEsSettings = function* (settings) {
  const {
    id,
    locationName,
    activitySector,
    practiceSetting,
    finess,
    serviceName,
  } = settings;

  // const activitySectors = yield select(getInteropCodesFromState, 'activitySectors');
  // const practiceSettings = yield select(getInteropCodesFromState, 'practiceSettings');

  const validator = yup.object({
    id: yup.string(), // .required('L\'identifiant est manquant'),
    locationName: yup.string(), // .required('Le nom de l\'établissement est manquant'),
    activitySector: yup.string(), // .oneOf(
    //   activitySectors.map(ps => ps.code),
    //   ({ values }) => `Le secteur d'activité doit être une des valeurs suivantes : ${values}`,
    // )
    // .required('Le secteur d\'activité est manquant')

    practiceSetting: yup.string(), // .oneOf(
    //   practiceSettings.map(ps => ps.code),
    //   ({ values }) => `Le cadre d'exercice doit être une des valeurs suivantes : ${values}`,
    // )
    // .required('Le cadre d\'exercice est manquant')

    finess: yup.string(),
    // TODO : make serviceName required when this becomes mandatory in the connector
    serviceNAme: yup.string(), // .required('Le nom du service est manquant'),
  });

  try {
    validator.validateSync(settings, { abortEarly: false });

    if (id) yield put(setESConfiguration('es_id', id));
    if (locationName) yield put(setESConfiguration('practiceLocationName', locationName));
    if (activitySector) yield put(setESConfiguration('activitySector', activitySector));
    if (practiceSetting) yield put(setESConfiguration('practiceSetting', practiceSetting));
    if (finess) yield put(setESConfiguration('es_finess', finess));
    if (serviceName) yield put(setESConfiguration('serviceName', serviceName));

    toast.dismiss('es-config');
    toast.success('Configuration de l\'établissement mise à jour', {
      autoClose: 2000,
      toastId: 'es-config',
    });
  } catch (validationError) {
    const details = [
      createErrorDetails('Errors', validationError.inner.map(error => ({
        field: error.path,
        value: error.value,
        error: error.message,
      }))),
      createErrorDetails('provided', settings),
    ];
    const modalError = createModalError({
      i_apiErrorType: errorTypes.SoftwareErrors,
      i_apiErrorCode: softwareErrors.INVALID_ES_SETTINGS,
    }, details);

    yield put(setModalError(modalError));
  }
};

const handleConnectorUrl = function* (url) {
  if (isUrlValid(url)) {
    yield put(setGlobalConfiguration('esRestUrl', url));
    toast.dismiss('esRestUrl');
    toast.success('Adresse d\'accès au connecteur REST mise à jour', {
      autoClose: 2000,
      toastId: 'esRestUrl',
    });
  } else {
    toast.error((
      // eslint-disable-next-line react/jsx-filename-extension
      <div>
        <div className="font-weight-bold">Erreur</div>
        <span>L&apos;adresse d&apos;accès au connecteur REST fournie n&apos;est pas valide</span>
      </div>
    ), {
      autoClose: false,
      toastId: 'esRestUrl',
    });
  }
};

const handleSettings = function* (settings) {
  if (settings.dpExportUrl) {
    try {
      const url = settings.dpExportUrl;
      if (isUrlValid(url)) {
        yield put(setDpConfiguration('dpExportUrl', url));
        toast.dismiss('dpExportUrl');
        toast.success('Adresse d\'export des dispensations mise à jour', {
          autoClose: 2000,
          toastId: 'dpExportUrl',
        });
      } else {
        throw new Error('Url is invalid');
      }
    } catch (e) {
      toast.error((
        // eslint-disable-next-line react/jsx-filename-extension
        <div>
          <div className="font-weight-bold">Erreur</div>
          <span>L&apos;adresse d&apos;export des dispensations fournie n&apos;est pas valide</span>
        </div>
      ), {
        autoClose: false,
        toastId: 'dpExportUrl',
      });
    }
  }

  if (settings.hpSpeciality) yield put(setESConfiguration('hpSpeciality', settings.hpSpeciality));

  if (settings.airAuthenticationMode) {
    const mode = Object.values(availableAirStrongAuthModes).find(authMode => authMode.value === Number(settings.airAuthenticationMode));
    if (!mode) {
      const details = [createErrorDetails('Erreur détaillée', settings)];
      const modalError = createModalError({
        i_apiErrorType: errorTypes.SoftwareErrors,
        i_apiErrorCode: softwareErrors.AIR_AUTH_MODE_INVALID,
      }, details);

      yield put(setModalError(modalError));
    } else {
      yield put(setESConfiguration('hpAuthenticationMode', getEsUserAuthenticationMode(settings.airAuthenticationMode)));
      yield put(setESConfiguration('hpAuthenticationContext', getEsUserAuthenticationContext(settings.airAuthenticationMode)));
    }
  } else {
    if (settings.hpAuthenticationMode) {
      yield put(setESConfiguration('hpAuthenticationMode', settings.hpAuthenticationMode));
    }
    if (settings.hpAuthenticationMode) {
      yield put(setESConfiguration('hpAuthenticationContext', settings.hpAuthenticationContext));
    }
  }
};

// const isInitialized = state => state.dmpconnectInit.applicationInitialized;
const handleMail = function* (mail, pathname) {
  if (mail) {
    yield put(resetMssEmailContent(mail.attachments.length > 0));

    yield put(setMssEmailContent({
      recipients: mail.recipients,
      cc: mail.cc,
      bcc: mail.bcc,
      title: mail.title,
      messageContent: mail.messageContent,
      isHtml: mail.isHtml,
      senderWording: mail.senderWording,
      replyTo: mail.replyTo,
      messageId: mail.messageId,
      inReplyToMessageIds: mail.inReplyToMessageIds,
      references: mail.references,
      attachments: mail.attachments.map(att => ({
        patientIns: att.s_patientIns,
        fileContentInBase64: att.s_fileContentInBase64,
        documentTitle: att.s_documentTitle,
        documentDescription: att.s_documentDescription,
        documentCategory: att.s_documentCategory,
        documentFormat: att.i_documentFormat,
        healthcareSetting: att.s_healthcareSetting,
        versionNumber: att.s_versionNumber,
        setIdRoot: att.s_setIdRoot,
        setIdExtension: att.s_setIdExtension,
        uniqueId: att.s_uniqueId,
        replacedDocumentUniqueId: att.s_replacedDocumentUniqueId,
        performer: att.performer,
        treatingPhysician: att.treatingPhysician,
        additionalAuthors: att.additionalAuthors,
        informants: att.informants,
        intendedRecipients: att.intendedRecipients,
        EventCodes: att.EventCodes,
      })),
    }));
    yield put(setShowMssPopup(true));
    let redirect = pathname;
    if (pathname === '/') {
      redirect = '/home/';
    }
    yield put(push(redirect));
  }
};

const checkSaasToken = function* (providedToken, saasTokenConfig) {
  const { saasToken, validatedSaasTokens = [] } = saasTokenConfig;
  let validated = false;

  if (saasToken && saasToken !== '') {
    // check if token has already been validated
    if (validatedSaasTokens) {
      const validatedToken = validatedSaasTokens.some(t => t.token === saasToken);
      if (validatedToken) {
        validated = true;
      }
    }

    if (validated === false) {
      // validate the provided token agains configured one
      if (providedToken === saasToken) {
        const newValidatedTokens = [
          ...validatedSaasTokens,
          {
            token: providedToken,
            validatedAt: moment().unix(),
          },
        ];
        // set token as validated
        yield put(setPersistedAppConfiguration('validatedSaasTokens', newValidatedTokens));
        validated = true;
      }
    }
  } else {
    validated = true;
  }
  yield put(setSaasTokenValidated(validated));
  return validated;
};


const handleReadersChange = function* (readers) {
  const pcscReadersSection = yield select((state) => {
    const { dmpconnect: { [apiSections.PCSC_READERS_SECTION]: readersSection } } = state;
    return readersSection;
  });

  if (!isReady(pcscReadersSection) && !hasError(pcscReadersSection) && !isLoading(pcscReadersSection)) {
    const apiType = yield select(getApiType);
    const sessionId = yield select(getSessionId);
    const esRestVitale = yield select((state) => {
      const { dmpconnectPersistedConnectorConfiguration } = state;
      return getConfigurationValue('esRestVitale', dmpconnectPersistedConnectorConfiguration);
    });

    if (
      (!!sessionId && apiType !== API_TYPES.REST)
        || (apiType === API_TYPES.REST && esRestVitale)
    ) {
      yield put(clearSection(apiSections.PCSC_READERS_SECTION));
      yield put(requestPcscReaders(false));

      yield take([
        dmpCommandSuccessContextualizedType(apiSections.PCSC_READERS_SECTION),
        dmpCommandFailureContextualizedType(apiSections.PCSC_READERS_SECTION),
      ]);

      yield call(handleReadersChange, readers);
    }
  }
  const { Readers: pcscReaders = [] } = pcscReadersSection;
  const { cpxReader, vitaleReader } = readers;

  if (cpxReader) {
    const index = pcscReaders.findIndex(r => r.s_name.toLowerCase() === cpxReader.toLowerCase());
    yield put(setPersistedConnectorConfiguration('cpxCardReader', index));
    yield put(setForcedCpxReader(index));
  }
  if (vitaleReader) {
    const index = pcscReaders.findIndex(r => r.s_name.toLowerCase() === vitaleReader.toLowerCase());
    yield put(setPersistedConnectorConfiguration('vitaleCardReader', index));
    yield put(setForcedVitaleReader(index));
  }
};


const handleMssConfigChange = function* (mssOperatorConfig) {
  const currentMssConfig = yield select(state => ({ ...state.dmpconnectMSSConfiguration }));

  const validationSchema = yup.object({
    operator: yup.string().oneOf(
      currentMssConfig.mssOperatorsConfig.map(c => c.id),
      ({ values }) => `L'opérateur doit être une des valeurs suivantes : ${values}`,
    ),
    loginType: yup.string().oneOf(
      Object.values(mssLoginTypes),
      ({ values }) => `Le type de connexion doit être une des valeurs suivantes : ${values}`,
    ),
    apiType: yup.string().oneOf(
      Object.values(mssSubTypes),
      ({ values }) => `Le type d'API doit être une des valeurs suivantes : ${values}`,
    ),
    imapServer: yup.string(),
    smtpServer: yup.string(),
    imapPort: yup.number(),
    smtpPort: yup.number(),
  });

  try {
    validationSchema.validateSync(mssOperatorConfig, { abortEarly: false });

    let newMssConfig;
    const {
      operator,
      loginType,
      apiType,
      imapServer,
      smtpServer,
      imapPort,
      smtpPort,
    } = mssOperatorConfig;
    if (operator) {
      const config = (currentMssConfig.mssOperatorsConfig || []).find(c => c.id === operator);
      const configFromOperator = getMssConfigFromOperatorConfig(config);
      newMssConfig = { ...currentMssConfig, ...configFromOperator };
    } else {
      newMssConfig = {
        ...currentMssConfig,
        mssLoginType: loginType,
        mssApiType: apiType,

        mssImapServer: imapServer,
        mssSmtpServer: smtpServer,

        mssImapPort: imapPort,
        mssSmtpPort: smtpPort,

        mssOperator: 'custom',
      };
    }
    yield put(resetMssConfiguration(newMssConfig));
  } catch (e) {
    const details = [];
    if (e instanceof ValidationError) {
      details.push(createErrorDetails('Erreur détaillée', e.inner.map(error => ({
        field: error.path ? error.path.replace('.value', '') : undefined,
        value: error.path ? error.value : undefined,
        error: error.message,
      }))));
    } else {
      details.push(createErrorDetails('Erreur détaillée', e.message));
    }

    yield put(setModalError(createModalError(
      { ...createError(errorTypes.SoftwareErrors, softwareErrors.PARAMS_INVALID_JSON) },
      details,
    )));
  }
};
const getConfigMssLoginType = ({ dmpconnectMSSConfiguration }) => dmpconnectMSSConfiguration.mssLoginType;

export const handleLocationChange = function* () {
  while (true) {
    const { payload } = yield take(LOCATION_CHANGE);
    const { location: { pathname, hash, search } } = payload;
    if (pathname === '/login-token') {
      yield put(logoutSuccess());
    }

    const cleanedHash = hash.slice(1);
    const cleanedSearch = search.slice(1);
    const mergedSearchAndHash = mergeSearchAndHash(cleanedSearch, cleanedHash);
    const parameters = parseQueryString(mergedSearchAndHash);
    const { params, user, person } = parameters || {};
    const saasTokenConfig = yield select(getSaasTokenConfig);
    const esConfig = yield select(getDmpconnectESConfiguration);
    const esLoginType = getConfigurationValue('loginType', esConfig);
    const connectorConfig = yield select(getDmpconnectPersistedConnectorConfig);
    const onlyGeneratedConnectorJWT = getConfigurationValue('onlyGeneratedConnectorJWT', connectorConfig);
    let decodedParams;
    let parsedParams;
    const loggedIn = yield call(checkIfUserIsLoggedIn, payload);
    const { accessRights: { psId } } = yield select(getAccessRightsProps);
    const mssLoginType = yield select(getConfigMssLoginType);
    try {
      yield put(clearModalError());

      let LoginCheck;
      let Ins;
      let Search;
      let Settings;
      let Mail;
      let tseId;
      let saasToken;
      let jwtSecret;
      let connectorJwt;
      let Es;
      let apiLoginCheck;
      let deactivateDashboard;
      let esRestUrl;
      let mssEmail;
      let mssCertificateId;
      let mssCertificateBase64;
      let submitEngine;
      let authenticationTab;
      let readers;
      let mssClientMode;
      let mssOperatorConfig;

      if (params) {
        // base64 decode
        decodedParams = b64DecodeUnicode(decodeURIComponent(params));
        
        // parse json
        parsedParams = JSON.parse(decodedParams);

        ({
          LoginCheck, Ins, Search, Settings, Mail, tseId, saasToken, jwt_secret: jwtSecret, Es, connectorJwt,
          apiLoginCheck,
          deactivateDashboard,
          esRestUrl,
          mssEmail, mssCertificateId, mssCertificateBase64,
          submitEngine,
          authenticationTab,
          mssClientMode,
          mssOperatorConfig,
          readers,
        } = parsedParams || {});
      }

      if (deactivateDashboard !== undefined) {
        yield put(setPersistedAppConfiguration('noDashboard', !!deactivateDashboard));
      }

      const saasTokenValidated = yield call(checkSaasToken, saasToken, saasTokenConfig);
      if (saasTokenValidated) {
        if (Es) {
          yield call(handleEsSettings, Es);
          if (!Ins && !Search) {
            yield put(clearLoginReferer());
          }
        }

        if (connectorJwt) {
          yield put(setPersistedConnectorConfiguration('connectorJWT', connectorJwt));
          if (!Ins && !Search) {
            yield put(clearLoginReferer());
          }
        } else if (jwtSecret && !onlyGeneratedConnectorJWT) {
          yield call(generateAndSaveToken, jwtSecret);
          if (!Ins && !Search) {
            yield put(clearLoginReferer());
          }
        }

        if (Settings) {
          yield call(handleSettings, Settings);
        }

        if (apiLoginCheck) {
          yield call(handleApiLoginCheck, apiLoginCheck);
        } else if (LoginCheck) {
          yield call(handleLoginCheck, LoginCheck);
        }

        if (esRestUrl) {
          yield call(handleConnectorUrl, esRestUrl);
        }

        if (mssLoginType === mssLoginTypes.CERT) {
          if (mssEmail) {
            yield put(setUserConfiguration(psId, { mssEmail }));
            toast.success('Adresse email MSS mise à jour', {
              autoClose: 2000,
              toastId: 'mssEmail',
            });
          }
          if (mssCertificateBase64) {
            yield put(setESConfiguration('esMssCertificate', mssCertificateBase64));
            toast.success('Certificat MSS mis à jour', {
              autoClose: 2000,
              toastId: 'mssCertificateBase64',
            });
          }
          if (mssCertificateId) {
            yield put(setESConfiguration('es_id_mss', mssCertificateId));
            toast.success('Identifiant de certificat MSS mis à jour', {
              autoClose: 2000,
              toastId: 'mssCertificateId',
            });
          }
        }

        if (mssOperatorConfig) {
          yield call(handleMssConfigChange, mssOperatorConfig);
        }

        if (tseId && pathname === '/') {
          yield put(setTseConfiguration('whoami', tseId));
          yield put(setTseConfiguration('active', true));
          toast.success('Identifiant utilisateur TSE mis à jour', {
            autoClose: 2000,
            toastId: 'whoami',
          });
          if (Ins || Search) {
            // envoyer le reste de params pour prendre en compte les recherches
            yield put(replace(`/#params=${base64.encode(JSON.stringify({ Ins, Search }))}`));
          } else {
            yield put(replace('/'));
          }
        }

        if (submitEngine) {
          yield put(setSubmitEngine(submitEngine));
        }

        if (authenticationTab) {
          const apiType = yield select(getApiType);
          if (env.REACT_APP_API_TYPE === API_TYPES.REST && esConfig.cpxLoginActive) {
            if (authenticationTab === 'es' && apiType === API_TYPES.WS) {
              yield put(setGlobalConfiguration('apiType', API_TYPES.REST));
            }
            if (authenticationTab === 'cpx' && apiType === API_TYPES.REST) {
              yield put(setGlobalConfiguration('apiType', API_TYPES.WS));
            }
          }
          yield put(setLoginTab(authenticationTab));
        }

        if (mssClientMode !== undefined && mssClientMode !== null) {
          yield put(setPersistedAppConfiguration('mssClientMode', !!mssClientMode));
          toast.success(`Mode MSS Client ${!!mssClientMode === true ? 'activé' : 'désactivé'}`);
          if (!Ins && !Search) {
            yield put(clearLoginReferer());
          }
        }

        if (readers) {
          yield call(handleReadersChange, readers);
        }

        if (loggedIn) {
          yield call(handleSearchFromUrl, Ins, Search, pathname);
          yield call(handleMail, Mail, pathname);
          yield call(handleShowVSMorRH, payload);
        } else if (esLoginType === esLoginTypes.API && user && person) {
          yield put(clearLoginReferer());
          yield put(setPersistedAppConfiguration('apiLoginValues', {
            psInternalId: user,
            patientInternalId: person,
            redirect: Ins || Search ? base64.encode(JSON.stringify({ Ins, Search })) : null,
          }));
        }

        // else if (esLoginType === esLoginTypes.API && internal_ids) {
        //   yield put(clearLoginReferer());
        //   // base64 decode
        //   decodedParams = b64DecodeUnicode(internal_ids);
        //   // parse json
        //   const { psInternalId, patientInternalId } = JSON.parse(decodedParams);
        //   yield put(setPersistedAppConfiguration('apiLoginValues', { psInternalId, patientInternalId }));
        // }
      }
    } catch (e) {
      console.log('error params', e);
      const error = createError(errorTypes.SoftwareErrors, softwareErrors.PARAMS_INVALID_JSON);
      const details = [
        createErrorDetails('provided', {
          base64: parameters,
          decoded: decodedParams,
        }),
      ];
      const modalError = createModalError(error, details);
      yield put(setModalError(modalError));
    }
    yield put(setUrlProcessed(true));
  }
};
