import * as yup from 'yup';
import moment from 'moment';
import 'moment/locale/fr';

yup.addMethod(yup.string, 'date', function () {
  return this.test('valid-date', 'Date invalide', function (value) {
    if (value === '' || value === undefined) return true;
    const date = moment(value, ['DD/MM/YYYY', 'YYYYMMDD', 'YYYY-MM-DD'], true);
    const isRankValid = date.year() >= 1600 && date.year() <= 2100;
    const { createError } = this;
    return (date.isValid() && isRankValid) || createError('Date invalide');
  });
});

yup.addMethod(yup.string, 'pastDate', function () {
  return this.test('valid-past-date', 'La date ne peut être dans le futur', (value) => {
    if (value === '' || value === undefined) return true;
    const date = moment(value, ['DD/MM/YYYY', 'YYYYMMDD', 'YYYY-MM-DD'], true);
    return date < new Date();
  });
});

yup.addMethod(yup.string, 'DMPCNamesSpecChars', function () {
  return this.matches(/^[a-zA-Zà-úÀ-Ú'\- ]*$/,
    'Caratères autorisés : lettres, tiret (-), apostrophe (\'), espace ( )');
});

yup.addMethod(yup.string, 'DMPCAddressSpecChars', function () {
  return this.matches(/^[0-9a-zA-Zà-úÀ-Ú'\-/, ]*$/,
    'Caratères autorisés : chiffres, lettres, tiret (-), apostrophe (\'), espace ( ), slash (/)');
});

yup.addMethod(yup.string, 'DMPCMaxLength', function (length = 80) {
  return this.max(length, `Ce champ ne peut excéder ${length} caractères`);
});

yup.addMethod(yup.string, 'DMPCName', function (length = 80) {
  return this.DMPCMaxLength(length).DMPCNamesSpecChars();
});

yup.addMethod(yup.string, 'DMPCAdress', function (length = 80) {
  return this.DMPCMaxLength(length).DMPCAddressSpecChars();
});

yup.addMethod(yup.string, 'DMPCTel', function () {
  return this.matches(/^(\d{2}\s*\d{2}\s*\d{2}\s*\d{2}\s*\d{2})*$/, 'Numéro de téléphone invalide');
});
yup.addMethod(yup.string, 'DMPCMobile', function () {
  return this.matches(/^((06|07)\s*\d{2}\s*\d{2}\s*\d{2}\s*\d{2})*$/,
    'Numéro de mobile invalide (doit commencer par 06 ou 07)');
});

yup.addMethod(yup.string, 'INSiNames', function () {
  return this.uppercase()
    .matches(/^[A-Z']{1}[A-Z'\- ]*$/, 'Caratères autorisés : lettres majuscules, tiret (-), apostrophe (\'), espace ( )')
    .matches(/^(?:(?!\s{2}).)*$/, 'Les espaces ne peuvent être doublonnés')
    .matches(/^(?:(?!'{2}).)*$/, 'Les apostrophes ne peuvent être doublonnés');
});


yup.addMethod(yup.string, 'DigitsOnly', function () {
  return this.uppercase().matches(/^[0-9]*$/,
    'Ne peut contenir que des chiffres');
});
