import { apiSections } from 'dmpconnectjsapp-base/constants';
import { isReady } from 'dmpconnectjsapp-base/helpers/common';
import { readerSlotTypes } from '../../constants';

/**
 * Returns the reader index with the active given slot type
 * @param readers
 * @param slotType
 * @return {*}
 */
export const slotTypeInsertedReader = (readers, slotType) => readers && readers.findIndex(
  reader => (
    reader.i_slotType === slotType
    || (slotType === readerSlotTypes.VITALE && reader.s_name.toLowerCase() === 'vitale')
    || (slotType === readerSlotTypes.CPS && reader.s_name.toLowerCase().indexOf('pss reader on') !== -1)
  ),
);

export const getActiveReadersCount = readers => readers && readers.filter(r => r.s_accessMode !== 'GALSS/PCSC').length;

export const checkReaderConfig = (readerIndex, slotType, readers) => {
  if (readers.length === 0) return false;

  const slotTypesToCheck = slotType === readerSlotTypes.VITALE ? [readerSlotTypes.NONE, slotType] : [slotType];

  const readerFromConfig = readers[readerIndex];

  return (
    (readerFromConfig && slotTypesToCheck.includes(readerFromConfig.i_slotType))
  );
};

export const getReaderIndex = (readerIndex, slotType, readers) => {
  // if (Number(readerIndex) !== -1 && !readers[readerIndex]) return -1;

  // si on a un index de lecteur et qu'il est du bon type, on renvoie cet index
  // pour éviter de tout le temps renvoyer la première carte du bon type, si plusieurs cartes du même type sont insérées
  if (Number(readerIndex) !== -1 && checkReaderConfig(readerIndex, slotType, readers)) {
    return readerIndex;
  }
  return slotTypeInsertedReader(readers, slotType);

  // if (reader !== -1) return reader;
  //
  // return readerIndex;
};

export const getReaderName = (state, readerVariable) => {
  const {
    dmpconnect: {
      [apiSections.PCSC_READERS_SECTION]: pcscReaders,
    },
    dmpconnectPersistedConnectorConfiguration: {
      [readerVariable]: readerNumber,
    },
  } = state;

  if (isReady(pcscReaders)) {
    const { Readers = [] } = pcscReaders;
    if (Readers && Readers[readerNumber]) {
      return Readers[readerNumber].s_name;
    }
  }

  return null;
};
