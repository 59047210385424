import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getConfigurationValue, getInteropCodesFromState } from 'dmpconnectjsapp-base/helpers/accessors';
import { connect } from 'react-redux';
import { esLoginTypes } from 'dmpconnectjsapp-base/reducers/dmpconnectESConfiguration';
import { apiSections, disabledOpCodes } from 'dmpconnectjsapp-base/constants';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import {
  getError, hasError, isReady,
} from 'dmpconnectjsapp-base/helpers/common';
import { clearSection } from 'dmpconnectjsapp-base/actions';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UserSettingInput from '../DMPConfiguration/Input/UserSettingInput';
import dmpTools from '../../dmpconnect/tools/dmpTools';
import { getAction, setESConfiguration, setTseConfiguration } from '../../dmpconnect/actions';
import { getErrorMessage } from '../../dmpconnect/errors';
import Popable from '../Common/Modal/Popable';
import SwitchCheckbox from '../Common/Form/Input/SwitchCheckbox';
import { isModalError } from '../../dmpconnect/helpers/error';
import env from "../../envVariables";

const EsConfigForm = ({
  dispatch,
  practiceSettings,
  activitySectors,
  healthcareSettings,
  es_id,
  esCertificate,
  practiceLocationName,
  activitySector,
  practiceSetting,
  healthcareSetting,
  loginType,
  esLoginActive,
  esInfos,
  tseActive, tseWhoami,
  cpxLoginActive,
}) => {
  const [esId, setEsId] = React.useState(loginType !== esLoginTypes.CONNECTOR_LOGIN_PASSWD ? es_id : '');
  const [configId, setConfigId] = React.useState(loginType === esLoginTypes.CONNECTOR_LOGIN_PASSWD ? es_id : '');
  const [cert, setCert] = React.useState(esCertificate);
  const [loginMode, setLoginMode] = React.useState(loginType);
  const [name, setName] = React.useState(practiceLocationName);
  const [activity, setActivity] = React.useState(activitySector);
  const [practice, setPractice] = React.useState(practiceSetting);
  const [healthcare, setHealthcare] = React.useState(healthcareSetting);
  const [isTseActive, setTseActive] = React.useState(tseActive);
  const [whoami, setWhoami] = React.useState(tseWhoami);
  const [isCpxLoginActive, setCpxLoginActive] = React.useState(cpxLoginActive);
  const [idDebounceHandle, setIdDebounceHandle] = React.useState();


  const checkEsId = () => {
    if (loginMode === esLoginTypes.CONNECTOR_LOGIN_PASSWD && configId && configId !== '') {
      dispatch(getAction(
        commands.getEsInfos,
        apiSections.GET_ES_INFOS,
        { s_esId: configId },
      ));
    }
  };

  const submit = () => {
    dispatch(setESConfiguration('loginType', loginMode));
    if (loginMode !== esLoginTypes.CONNECTOR_LOGIN_PASSWD) {
      dispatch(setESConfiguration('es_id', esId));
    } else {
      dispatch(setESConfiguration('es_id', configId));
    }
    dispatch(setESConfiguration('esCertificate', cert));
    dispatch(setESConfiguration('practiceLocationName', name));
    dispatch(setESConfiguration('activitySector', activity));
    dispatch(setESConfiguration('practiceSetting', practice));
    dispatch(setESConfiguration('healthcareSetting', healthcare));
    dispatch(setTseConfiguration('active', isTseActive));
    dispatch(setTseConfiguration('whoami', whoami));
    dispatch(setESConfiguration('cpxLoginActive', isCpxLoginActive));
  };

  React.useEffect(() => {
    dispatch(clearSection(apiSections.GET_ES_INFOS));
    clearTimeout(idDebounceHandle);
    setIdDebounceHandle(setTimeout(() => {
      checkEsId();
    }, 500));
  }, [configId]);

  React.useEffect(() => {
    dispatch(clearSection(apiSections.GET_ES_INFOS));
    checkEsId();
  }, [loginMode]);

  const isValid = () => (
    practice && practice !== ''
    && healthcare && healthcare !== ''
    && (!isTseActive || whoami !== '')
    && (
      (
        loginMode === esLoginTypes.CONNECTOR_LOGIN_PASSWD
        && isReady(esInfos)
        && configId
      )
      || (
        loginMode !== esLoginTypes.CONNECTOR_LOGIN_PASSWD
        && esId
        && cert && cert !== ''
        && name && name !== ''
        && activity && activity !== ''
      )
    )
  );
  let error;
  let modalError;
  if (hasError(esInfos)) {
    const esInfosError = getError(esInfos);
    modalError = isModalError(esInfosError);
    error = getErrorMessage(esInfosError);
  }

  return (
    <Popable
      popup={false}
      card
      title="Configuration"
      shown
      footer={(
        <Form.Group className="d-flex justify-content-between mb-0">
          <Form.Text>
            <i className="la la-info-circle" />
            Tous les champs sont obligatoires.
          </Form.Text>
          <Button
            disabled={!isValid()}
            onClick={() => submit()}
          >
            Valider
          </Button>
        </Form.Group>
      )}
    >
      <h4>Configuration de la structure de soin</h4>
      <Form>
        <Form.Group>
          <Form.Label>
            Mode de connexion
          </Form.Label>
          <Form.Control
            as="select"
            id="loginType"
            custom
            disabled={!esLoginActive}
            onChange={event => setLoginMode(event.target.value)}
            name="loginType"
            value={loginMode}
          >
            {
              Object.values(esLoginTypes)
                .filter(type => Number(env.REACT_APP_DEBUG) === 1 || type !== esLoginTypes.LOGIN_PASSWD)
                .map(type => <option value={type}>{type}</option>)
            }
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {loginMode !== esLoginTypes.CONNECTOR_LOGIN_PASSWD ? 'ES ID' : 'Identifiant de configuration'}
          </Form.Label>
          <Form.Control
            onChange={(event) => {
              if (loginMode !== esLoginTypes.CONNECTOR_LOGIN_PASSWD) {
                setEsId(event.target.value);
              } else {
                setConfigId(event.target.value);
              }
            }}
            isInvalid={!!error}
            name="es_id"
            value={loginMode !== esLoginTypes.CONNECTOR_LOGIN_PASSWD ? esId : configId}
          />
          {/* {isLoading(esInfos) && ( */}
          {/*  <Form.Text id="es_id_validating" muted> */}
          {/*    <Spinner animation="border" size="sm" className="mr-1" /> */}
          {/*    Vérification en cours ... */}
          {/*  </Form.Text> */}
          {/* )} */}
          {!modalError && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {loginMode !== esLoginTypes.CONNECTOR_LOGIN_PASSWD && (
        <>
          <Form.Group>
            <Form.Label>
              Certificat ES
            </Form.Label>
            <Form.Control
              disabled={!esLoginActive}
              as="textarea"
              onChange={event => setCert(event.target.value)}
              name="esCertificate"
              value={cert}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Nom de la structure
            </Form.Label>
            <Form.Control
              disabled={!esLoginActive}
              onChange={event => setName(event.target.value)}
              name="practiceLocationName"
              value={name}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Secteur d&apos;activité
            </Form.Label>
            <UserSettingInput
              disabled={!esLoginActive}
              choices={dmpTools.getChoicesFiltered(activitySectors, disabledOpCodes)}
              onChange={event => setActivity(event.target.value)}
              name="activitySector"
              value={activity}
            />
          </Form.Group>
        </>
        )}
        <Form.Group>
          <Form.Label>
            Situation d&apos;exercice
          </Form.Label>
          <UserSettingInput
            disabled={!esLoginActive}
            choices={dmpTools.getChoicesFiltered(practiceSettings, disabledOpCodes)}
            onChange={event => setPractice(event.target.value)}
            name="practiceSetting"
            value={practice}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Cadre de soins
          </Form.Label>
          <UserSettingInput
            disabled={!esLoginActive}
            choices={dmpTools.getChoicesFiltered(healthcareSettings, disabledOpCodes)}
            onChange={event => setHealthcare(event.target.value)}
            name="healthcareSetting"
            value={healthcare}
          />
          <Form.Text muted>
            Veuillez vérifier la cohérence du cadre de soins par rapport à la situation d&apos;exercice sélectionnée.
          </Form.Text>
        </Form.Group>
      </Form>
      <Form>
        <h4>CPx</h4>
        <Form.Group as={Row} controlId="tseActive">
          <Form.Label column sm={5}>
            Autoriser la connexion par CPx
          </Form.Label>
          <Col>
            <SwitchCheckbox
              id="cpxLoginActive"
              onChange={event => setCpxLoginActive(event.target.checked)}
              name="cpxLoginActive"
              checked={isCpxLoginActive}
            />
          </Col>
        </Form.Group>
      </Form>
      <Form>
        <h4>TSE</h4>
        <Form.Group as={Row} controlId="tseActive">
          <Form.Label column sm={5}>
            Activer le mode TSE
          </Form.Label>
          <Col>
            <SwitchCheckbox
              id="tseActive"
              onChange={event => setTseActive(event.target.checked)}
              name="tseActive"
              checked={isTseActive}
            />
          </Col>
        </Form.Group>
        <Form.Group controlId="whoami">
          <Form.Label>
            Identifiant
          </Form.Label>
          <Form.Control
            onChange={event => setWhoami(event.target.value)}
            name="whoami"
            value={whoami}
            disabled={!isTseActive}
          />
          <Form.Text muted>
            <i className="la la-info-circle" />
            {' '}
            Résultat de la commande
            {' '}
            <i>whoami</i>
            {' '}
            dans l&apos;invite de commande windows
          </Form.Text>
        </Form.Group>
      </Form>
    </Popable>
  );
};

EsConfigForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  practiceSettings: PropTypes.array.isRequired,
  activitySectors: PropTypes.array.isRequired,
  healthcareSettings: PropTypes.array.isRequired,
  es_id: PropTypes.string,
  esCertificate: PropTypes.string,
  practiceLocationName: PropTypes.string,
  activitySector: PropTypes.string,
  practiceSetting: PropTypes.string,
  healthcareSetting: PropTypes.string,
  loginType: PropTypes.string,
  esLoginActive: PropTypes.bool,
  esInfos: PropTypes.object,
  tseActive: PropTypes.bool,
  tseWhoami: PropTypes.string,
  cpxLoginActive: PropTypes.bool,
};

EsConfigForm.defaultProps = {
  es_id: '',
  esCertificate: '',
  practiceLocationName: '',
  activitySector: '',
  practiceSetting: '',
  healthcareSetting: '',
  loginType: '',
  esLoginActive: false,
  esInfos: {},
  tseActive: false,
  tseWhoami: '',
  cpxLoginActive: false,
};

function mapStateToProps(state) {
  const {
    dmpconnectESConfiguration,
    dmpconnectTSEConfiguration,
    dmpconnect: {
      [apiSections.GET_ES_INFOS]: esInfos = {},
    },
  } = state;
  return {
    practiceSettings: getInteropCodesFromState(state, 'practiceSettings'),
    activitySectors: getInteropCodesFromState(state, 'activitySectors'),
    healthcareSettings: getInteropCodesFromState(state, 'healthcareSettings'),
    professions: getInteropCodesFromState(state, 'professions'),
    physicianSpecialities: getInteropCodesFromState(state, 'physicianSpecialities'),
    loginType: getConfigurationValue('loginType', dmpconnectESConfiguration),
    esLoginActive: getConfigurationValue('esLoginActive', dmpconnectESConfiguration),
    es_id: getConfigurationValue('es_id', dmpconnectESConfiguration),
    esCertificate: getConfigurationValue('esCertificate', dmpconnectESConfiguration),
    practiceLocationName: getConfigurationValue('practiceLocationName', dmpconnectESConfiguration),
    activitySector: getConfigurationValue('activitySector', dmpconnectESConfiguration),
    practiceSetting: getConfigurationValue('practiceSetting', dmpconnectESConfiguration),
    healthcareSetting: getConfigurationValue('healthcareSetting', dmpconnectESConfiguration),
    cpxLoginActive: getConfigurationValue('cpxLoginActive', dmpconnectESConfiguration),
    tseActive: getConfigurationValue('active', dmpconnectTSEConfiguration),
    tseWhoami: getConfigurationValue('whoami', dmpconnectTSEConfiguration),
    esInfos,
  };
}
const connectedEsConfigForm = connect(mapStateToProps)(EsConfigForm);

export default connectedEsConfigForm;
