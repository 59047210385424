import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { softwareErrors } from 'dmpconnectjsapp-base/errors';
import { createError, createErrorDetails, createModalError } from '../../dmpconnect/errors';
import { errorTypes } from '../../dmpconnect/errors/errorConfiguration';
import { setModalError } from '../../dmpconnect/actions';
import ErrorModal from './ErrorModal';
import {mapStackTrace} from "sourcemapped-stacktrace";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { dispatch } = this.props;
    console.error('unexpected error', errorInfo);

    let e = error;
    const errorModal = createError(
      errorTypes.SoftwareErrors,
      softwareErrors.UNEXPECTED_ERROR,
    );

    if (error.name === 'ChunkLoadError') {
      e = error.originalError;
      // eslint-disable-next-line prefer-destructuring
      errorModal.i_apiErrorCode = softwareErrors.WEBAPP_UPDATED;
    }

    mapStackTrace(e.stack, (mappedStack) => {
      const details = [createErrorDetails('Error', {
        name: e.name,
        message: e.message,
        toString: e.toString(),
        stack: mappedStack.join('\n'),
        info: errorInfo,
      })];

      dispatch(setModalError(createModalError(errorModal, details)));
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return (
      <>
        {hasError ? <ErrorModal /> : children}
      </>
    );
  }
}

ErrorBoundary.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default connect()(ErrorBoundary);
