import { dmpconnectConfigurationActionConstants } from '../constants';

export const setUserPreferences = (preferences, persist = true) => ({
  type: dmpconnectConfigurationActionConstants.SET_USER_PREFERENCE,
  preferences,
  persist,
});

export const setPersistedAppConfiguration = (key, value) => ({
  type: dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_CONFIGURATION,
  key,
  value,
});


export const resetPersistedAppConfigurationFromEnv = () => ({
  type: dmpconnectConfigurationActionConstants.DMPC_RESET_PERSIST_APP_CONFIGURATION_FROM_ENV,
});

export const resetAllConfigFromEnv = () => ({
  type: dmpconnectConfigurationActionConstants.DMPC_RESET_ALL_CONFIGURATION_FROM_ENV,
});

export const setOpenIDConfigValue = (key, value) => ({
  type: dmpconnectConfigurationActionConstants.SET_OPENID_CONFIG,
  key,
  value,
});

export const resetOpenIDConfigFromEnv = () => ({
  type: dmpconnectConfigurationActionConstants.RESET_OPENID_CONFIG_FROM_ENV,
});

export const setPersistedMssSyncMessages = (emailAddress, messages) => ({
  type: dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MSS_SYNCMESSAGES,
  emailAddress,
  messages,
});

export const setPersistedMssFolders = (emailAddress, folders) => ({
  type: dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MSS_FOLDERS,
  folders,
  emailAddress,
});

export const markMessageAsRead = (emailAddress, messageIds, read) => ({
  type: dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MESSAGE_READ,
  messageIds,
  emailAddress,
  read,
});
export const moveMessagesTo = (emailAddress, messageIds, destinationFolderId) => ({
  type: dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MESSAGE_MOVED,
  messageIds,
  emailAddress,
  destinationFolderId,
});
export const deleteMessages = (emailAddress, messageIds) => ({
  type: dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MESSAGE_DELETED,
  messageIds,
  emailAddress,
});
