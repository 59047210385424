import OidcClient, {OidcClientSettings} from '../../lib/OidcClient';
import env from "../../envVariables";

let oidcClientSettings: OidcClientSettings = {
  // issuer: env.REACT_APP_ES_LOGIN_OPENID_ISSUER,
  clientId: env.REACT_APP_ES_LOGIN_OPENID_CLIENT_ID,
  clientSecret: env.REACT_APP_ES_LOGIN_OPENID_CLIENT_SECRET,
  signKey: env.REACT_APP_ES_LOGIN_OPENID_SIGN_KEY,
  authorizeEndpoint: env.REACT_APP_ES_LOGIN_OPENID_AUTH_ENDPOINT,
  tokenEndpoint: env.REACT_APP_ES_LOGIN_OPENID_TOKEN_ENDPOINT,
  jwksEndpoint: env.REACT_APP_ES_LOGIN_OPENID_JWKS_ENDPOINT,
  userInfoEndpoint: env.REACT_APP_ES_LOGIN_OPENID_USERINFOS_ENDPOINT,
  userInfoMethod: env.REACT_APP_ES_LOGIN_OPENID_USERINFOS_METHOD,
  endSessionEndpoint: env.REACT_APP_ES_LOGIN_OPENID_ENDSESSION_ENDPOINT,
  scope: env.REACT_APP_ES_LOGIN_OPENID_SCOPE,
  acrValues: env.REACT_APP_ES_LOGIN_OPENID_ACRVALUES,
  responseType: 'code',
  deactivatePCKE: Number(env.REACT_APP_ES_LOGIN_OPENID_PKCE) !== 1,
  redirectURI: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/authcallback`,
  logoutURI: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/logout`,
  callUserInfos: Number(env.REACT_APP_ES_LOGIN_OPENID_USERINFOS) === 1,
  userInfosMapper: env.REACT_APP_ES_LOGIN_OPENID_USERINFOS_MAPPER,
  loginCheckMapper: env.REACT_APP_ES_LOGIN_CHECK_MAPPING,
  connectorToken: undefined,
  apiType: 'WS',
  dcParams: env.REAT_APP_DCPARAMS,
};

const userManager = new OidcClient(oidcClientSettings);

export default userManager;
