import moment from 'moment';
import 'moment/locale/fr';
import { isTransactionAllowed, transactions } from 'dmpconnectjsapp-base/rules/accessRights';
import { getConfigurationValue } from 'dmpconnectjsapp-base/helpers/accessors';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import {
  getAdminUpdateUrl, getDocumentsUrl, getMobileLandingUrl, getParcoursSoinUrl,
} from './nav';
import { dmpLandingPages } from '../constants';
import env from '../../envVariables';

export const isMssActive = (state) => {
  const { userPreferences, dmpConnectPersistedAppConfiguration } = state;
  return (
    getConfigurationValue('mssClientMode', dmpConnectPersistedAppConfiguration)
      || (
        Number(env.REACT_APP_MSS) === 1
          && !!getConfigurationValue('mssActive', userPreferences)
      )
  );
};

export const getCurrentPathname = (state) => {
  const { router: { location: { pathname } } } = state;
  return pathname;
};

export const getCpxCardStatus = (state) => {
  const { dmpconnect } = state;
  const { cpxCard } = dmpconnect;
  return cpxCard.s_status;
};
export const getDmpconnectESConfiguration = (state) => {
  const { dmpconnectESConfiguration } = state;
  return dmpconnectESConfiguration;
};
export const getDmpconnectCPxConfiguration = (state) => {
  const { dmpconnectCPxConfiguration } = state;
  return dmpconnectCPxConfiguration;
};
export const getDmpconnectPersistedConfiguration = (state) => {
  const { dmpConnectPersistedAppConfiguration } = state;
  return dmpConnectPersistedAppConfiguration;
};
export const getUserPreferences = (state) => {
  const { userPreferences } = state;
  return userPreferences;
};
export const getEsUser = (state) => {
  const { dmpconnectUser: { esUser = null } } = state;
  return esUser;
};

export const getSessionId = (state) => {
  const { dmpconnect } = state;
  const { session } = dmpconnect;
  return session.s_sessionId;
};

export const isUserLoggedIn = (state) => {
  const { dmpconnectUser } = state;
  return dmpconnectUser.loggedIn;
};

export const getLoginReferer = (state) => {
  const { dmpconnectUser } = state;
  return dmpconnectUser.loginReferer;
};

export const getEsUserMetaDatas = (user) => {
  if (!user) return {};
  const {
    profile: metadata = {},
  } = user;
  return metadata;
};

export const extractDate = (date, format = 'DD/MM/YYYY') => moment(date, [format], true);

export const formatDate = (date, format = 'DD/MM/YYYY') => date.format(format);

export const getEsUserFromConfig = (dmpConfiguration) => {
  if (dmpConfiguration) {
    return {
      hpAuthenticationMode: dmpConfiguration.hpAuthenticationMode || 8,
      hpGiven: dmpConfiguration.hpGiven || 'Spécialiste',
      hpInternalId: dmpConfiguration.hpInternalId || 'INT14440',
      hpName: dmpConfiguration.hpName || 'Robert',
      hpProfession: dmpConfiguration.hpProfession || '10',
      hpProfessionOid: dmpConfiguration.hpProfessionOid || '1.2.250.1.71.1.2.7',
      hpSpeciality: dmpConfiguration.hpSpeciality || 'SM26',
      esId: dmpConfiguration.es_id,
      serviceName: dmpConfiguration.serviceName,
    };
  }
  return null;
};

export const getDocumentRedirectUrl = (accessRights, ins, dmpLandingPage = dmpLandingPages.PARCOURS_SOIN) => {
  if (dmpLandingPage === 'DMP_MOBILE_LANDING') {
    return getMobileLandingUrl(ins);
  }

  if (isTransactionAllowed(accessRights, transactions.FIND_DOCUMENTS_META)) {
    if (dmpLandingPage === dmpLandingPages.DOCUMENTS) {
      return getDocumentsUrl(ins);
    }
    return getParcoursSoinUrl(ins);
  }
  return getAdminUpdateUrl(ins);
};

export const isInsValid = ins => !['0', 0, '-1', -1, '-99', -99, null, undefined].includes(ins);

export const getSaasTokenConfig = (state) => {
  const { dmpConnectPersistedAppConfiguration: { saasToken, validatedSaasTokens } } = state;
  return { saasToken, validatedSaasTokens };
};

export const getDmpLandingPage = ({ userPreferences }) => (
  getConfigurationValue('dmpLandingPage', userPreferences)
);

export const getConnectorVersion = (state) => {
  const {
    dmpconnect: {
      [apiSections.SESSION_SECTION]: {
        s_serviceVersion,
      },
    },
  } = state;
  return s_serviceVersion;
};
export { getDocumentCdaContent } from './documentUtils';
