import { put, take, select } from 'redux-saga/effects';
import { accessModes, API_TYPES, apiSections } from 'dmpconnectjsapp-base/constants';
import {
  clearSection,
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType,
} from 'dmpconnectjsapp-base/actions';
import { authenticationTypes, getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { formatSetDMPAccessModeParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { getApiType } from 'dmpconnectjsapp-base/helpers/accessors';
import {
  getAction,
  resetFindDocumentsParams, selectCertifiedIdentity,
  setDashboardInitOk,
} from '../actions';

export function* dashboardInit() {
  const apiType = yield select(getApiType);
  const { accessRights: { authenticationType } } = yield select(getAccessRightsProps);
  yield put(selectCertifiedIdentity(null));

  if (authenticationType === authenticationTypes.DIRECT) {
    yield put(getAction(
      commands.getSessionState,
      apiSections.SESSION_STATE_SECTION,
      null,
      {
        // synchronous: true,
        silentError: false,
      },
    ));

    yield take([
      dmpCommandSuccessContextualizedType(apiSections.SESSION_STATE_SECTION),
      dmpCommandFailureContextualizedType(apiSections.SESSION_STATE_SECTION),
    ]);
  }

  if (
    (apiType !== API_TYPES.REST && authenticationType === authenticationTypes.DIRECT)
    || (apiType === API_TYPES.REST && authenticationType === authenticationTypes.AIR)
  ) {
    const dmpAccessModeParams = formatSetDMPAccessModeParams(accessModes.NormalAccess, '');
    yield put(getAction(
      commands.setDMPAccessMode,
      apiSections.DMP_ACCESS_MODE_SECTION,
      dmpAccessModeParams,
      {
        contextParams: { ...dmpAccessModeParams },
      },
    ));
    yield take([
      dmpCommandSuccessContextualizedType(apiSections.DMP_ACCESS_MODE_SECTION),
      dmpCommandFailureContextualizedType(apiSections.DMP_ACCESS_MODE_SECTION),
    ]);
  }

  yield put(clearSection(apiSections.FIND_DOCUMENTS_SECTION));
  yield put(clearSection(apiSections.CONFIDENTIALITY_LEVEL_SECTION));
  yield put(resetFindDocumentsParams());

  yield put(getAction(
    commands.setConfidentialityLevel,
    apiSections.CONFIDENTIALITY_LEVEL_SECTION,
    { i_enableSecretConnection: 0 },
    { subSection: 'all' },
  ));
  yield take([
    dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
    dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION_REST),
    dmpCommandFailureContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
  ]);

  yield put(setDashboardInitOk());
}
