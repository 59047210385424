export const dmpSexes = {
  UNKNOWN: 1,
  MALE: 2,
  FEMALE: 3,
};

export const stringSex = {
  U: dmpSexes.UNKNOWN,
  M: dmpSexes.MALE,
  F: dmpSexes.FEMALE,
};

export const shortSexes = {
  [dmpSexes.UNKNOWN]: 'U',
  [dmpSexes.MALE]: 'M',
  [dmpSexes.FEMALE]: 'F',
};

export const sexes = [
  { value: dmpSexes.UNKNOWN, label: 'Inconnu' },
  { value: dmpSexes.MALE, label: 'Homme' },
  { value: dmpSexes.FEMALE, label: 'Femme' },
];

export const dmpGenders = {
  UNKNOWN: 1,
  MR: 2,
  MRS: 3,
};

export const shortGenders = {
  [dmpGenders.MR]: 'M',
  [dmpGenders.MRS]: 'Mme',
};

export const genders = [
  { value: dmpGenders.UNKNOWN, label: 'Inconnu' },
  { value: dmpGenders.MR, label: 'Monsieur' },
  { value: dmpGenders.MRS, label: 'Madame' },
];

export const guardianRoles = {
  GUARDIAN_NONE: 0,
  GUARDIAN_FATHER: 1,
  GUARDIAN_MOTHER: 2,
  GUARDIAN_STEPFATHER: 3,
  GUARDIAN_STEPMOTHER: 4,
  GUARDIAN_GRANDFATHER: 5,
  GUARDIAN_GRANDMOTHER: 6,
  GUARDIAN_GREAT_GRANDFATHER: 7,
  GUARDIAN_GREAT_GRANDMOTHER: 8,
  GUARDIAN_AUNT: 9,
  GUARDIAN_UNCLE: 10,
  GUARDIAN_BROTHER: 11,
  GUARDIAN_SISTER: 12,
  GUARDIAN_GUARDIAN: 13,
};

export const guardianRolesList = [
  { value: guardianRoles.GUARDIAN_NONE, label: 'Aucun' },
  { value: guardianRoles.GUARDIAN_FATHER, label: 'Père' },
  { value: guardianRoles.GUARDIAN_MOTHER, label: 'Mère' },
  { value: guardianRoles.GUARDIAN_STEPFATHER, label: 'Beau-père' },
  { value: guardianRoles.GUARDIAN_STEPMOTHER, label: 'Belle-mère' },
  { value: guardianRoles.GUARDIAN_GRANDFATHER, label: 'Grand-père' },
  { value: guardianRoles.GUARDIAN_GRANDMOTHER, label: 'Grand-mère' },
  { value: guardianRoles.GUARDIAN_GREAT_GRANDFATHER, label: 'Arrière-grand-père' },
  { value: guardianRoles.GUARDIAN_GREAT_GRANDMOTHER, label: 'Arrière-grand-mère' },
  { value: guardianRoles.GUARDIAN_AUNT, label: 'Tante' },
  { value: guardianRoles.GUARDIAN_UNCLE, label: 'Oncle' },
  { value: guardianRoles.GUARDIAN_BROTHER, label: 'Frère' },
  { value: guardianRoles.GUARDIAN_SISTER, label: 'Sœur' },
  { value: guardianRoles.GUARDIAN_GUARDIAN, label: 'Tuteur' },
];


export const webPsUrlsLabels = {
  TableauDeBord: 'Tableau de bord',
  DossierPatient: 'Dossier patient',
  GestionDMPPatient: 'Gestion DMP du patient',
  Documents: 'Documents',
  ParcoursDeSoins: 'Parcours de soin',
  HistoriqueAcces: 'Historique d\'accès',
  Parametrages: 'Paramétrages',
  VolontesEtDroits: 'Volontés et droits',
};

export const otpChannelTypes = {
  BOTH: 0,
  MOBILE: 1,
  MAIL: 2,
};

export const cpxCardTypes = {
  PROD: 1,
  DEMO: 2,
};

export const cpxTypes = {
  CPS: 1, // Carte de Professionnel de Santé
  CPF: 2, // Carte de Professionnel de santé en Formation
  CPE: 3, // Carte de Personnel d'Etablissement (CDE, CPE)
  CPA: 4, // Carte de Personnel Administratif (CPA, CDA)
};

export const cpxTypeLabels = {
  [cpxTypes.CPS]: 'CPS', // Carte de Professionnel de Santé
  [cpxTypes.CPF]: 'CPF', // Carte de Professionnel de santé en Formation
  [cpxTypes.CPE]: 'CPE', // Carte de Personnel d'Etablissement (CDE, CPE)
  [cpxTypes.CPA]: 'CPA', // Carte de Personnel Administratif (CPA, CDA)
};

export const vitaleCardTypes = {
  REAL: 2,
  TEST: 3,
  DEMO: 4,
};
export const vitaleCardTypeLabels = {
  [vitaleCardTypes.REAL]: 'Réel',
  [vitaleCardTypes.TEST]: 'Test',
  [vitaleCardTypes.DEMO]: 'Démo',
};

export const insTypes = {
  DEMO_INS: 'D',
  TEST_INS: 'T',
  REAL_INS: 'N',
  TEMP_INS: 'NIA',
};

export const insOidToType = {
  '1.2.250.1.213.1.4.8': insTypes.REAL_INS,
  '1.2.250.1.213.1.4.10': insTypes.TEST_INS,
  '1.2.250.1.213.1.4.11': insTypes.DEMO_INS,
  '1.2.250.1.213.1.4.9': insTypes.TEMP_INS,
};
export const insTypeToOid = {
  [insTypes.REAL_INS]: '1.2.250.1.213.1.4.8',
  [insTypes.TEST_INS]: '1.2.250.1.213.1.4.10',
  [insTypes.DEMO_INS]: '1.2.250.1.213.1.4.11',
  [insTypes.TEMP_INS]: '1.2.250.1.213.1.4.9',
};

export const INSiIdentitySources = {
  VITALE: 'VITALE', // recherche depuis la carte vitale
  IDENTITY: 'IDENTITY', // recherche par traits
};
