import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import CustomTypeahead from '../../Common/Form/Input/CustomTypeahead';

const UserSettingInput = ({
  choices, value, error, name, isInvalid, labelKey, valueKey, ...props
}) => (
  <>
    <CustomTypeahead
      className={`custom-typeahead ${isInvalid ? 'is-invalid' : ''}`}
      name={name}
      id={name}
      options={choices}
      selectionRule={item => item[valueKey] === value}
      labelKey={labelKey}
      valueKey={valueKey}
      value={value}
      isInvalid={isInvalid || !choices.find(item => item[valueKey] === value)}
      inputProps={{ size: 'sm' }}
      {...props}
    />
    <Form.Control.Feedback type="invalid">
      {error}
    </Form.Control.Feedback>
  </>
);

UserSettingInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  choices: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  placeholder: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
};

UserSettingInput.defaultProps = {
  value: null,
  error: null,
  isInvalid: false,
  placeholder: 'Sélectionner une valeur ...',
  labelKey: 'label',
  valueKey: 'code',
};

export default UserSettingInput;
