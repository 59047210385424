import * as React from 'react';
import {
  call, put, race, select, take, actionChannel,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { toast } from 'react-toastify';
import rename from 'deep-rename-keys';
import {
  clearSection,
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType,
  dmpRemoteCommandFailureContextualizedType,
  dmpRemoteCommandSuccessContextualizedType,
  setGlobalConfiguration,
} from 'dmpconnectjsapp-base/actions';
import { websocketChannel } from 'dmpconnectjsapp-base/sagas/connectorSagas';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import {
  authenticationTypes,
  canUpload,
  getAccessRightsProps,
  isTransactionAllowed,
  transactions,
} from 'dmpconnectjsapp-base/rules/accessRights';
import commands, { mssSubTypes } from 'dmpconnectjsapp-base/actions/config/commands';
import {
  getApiType,
  getConfigurationValue,
  getDmpParameterFromState, getHealthcareSetting,
  getInteropCodesFromState, getMssEmail, getPracticeSetting,
} from 'dmpconnectjsapp-base/helpers/accessors';
import { encodeIns } from 'dmpconnectjsapp-base/utils/insUtils';
import { softwareErrors } from 'dmpconnectjsapp-base/errors';
import { ValidationError } from 'yup';
import {
  formatDeleteDocumentParams, formatGetMssHpInfosParams,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { js2xml, xml2js } from 'xml-js';
import { documentVisibilityStatuses, isHiddenFor } from 'dmpconnectjsapp-base/helpers/findDocuments';
import moment from 'moment';
import FileType from 'file-type/core';
import { hasCustomStyleSheetApplied } from 'dmpconnectjsapp-base/utils/clinicalDocument';
import { b64DecodeUnicode } from 'dmpconnectjsapp-base/utils/dataUtils';
import {
  dmpconnectConfigurationActionConstants,
  dmpconnectRemoteActionConstants,
  dmpStatuses,
  documentFormatNames, documentFormats,
  documentFormatsCda, documentFormatTypeMimes,
} from '../constants';

import {
  clearRemoteSection,
  remoteDeleteDocument,
  remoteExportEmailDone,
  remoteFindPatients,
  remoteGetCertifiedIdentity,
  remoteGetDirectAuthenticationDMPStatus, remoteSendDocument,
  remoteSubmitDoc,
  remoteValidateCDADocuments,
  sendRemoteResponse,
  stopRemoteControl,
} from '../actions/dmpconnectRemoteActions';

import {
  getCurrentPathname,
  getDmpLandingPage, getDocumentCdaContent,
  getDocumentRedirectUrl, getEsUser,
  getSessionId,
  isMssActive,
  isUserLoggedIn as isUserLoggedInAccessor,
} from '../helpers';
import { extractFindDmpParams, extractJSONFindDmpParams, generateFindDmpResponse } from '../helpers/remote/findDmp';
import { generateGetCurrentDmpBody, generateGetCurrentDmpResponse } from '../helpers/remote/getCurrentDmp';
import {
  convertJsResultToXmlReadyObject,
  generateRemoteFailureResponse,
  remoteActions,
  remoteFormats,
  remoteResponseChannels,
  remoteSources,
} from '../helpers/remote';
import { errorTypes } from '../errors/errorConfiguration';
import { extractGetMssInfosParams, extractJSONGetMssInfosParams, formatQuery } from '../helpers/remote/getMssHpInfos';
import {
  generateTestDmpExistenceResponse,
} from '../helpers/remote/testDmpExistence';
import { extractJSONSubmitDocumentParams, extractSubmitDocumentParams } from '../helpers/remote/submitDocument';
import {
  extractGetCertifiedIdentityParams,
  extractJSONGetCertifiedIdentityParams,
  generateGetCertifiedIdentityResponse,
} from '../helpers/remote/getCertifiedIdentidy';
import {
  getAction,
  logout,
  resetMssEmailContent,
  selectINS,
  setModalError,
  setMssEmailContent,
  setShowMssPopup,
  toggleLoadingBackdrop,
} from '../actions';

import { createErrorDetails, createModalError } from '../errors';
import { generateGetEfficienceVersionResponse } from '../helpers/remote/getEfficienceVersion';
import { insTypes } from '../constants/dmpConstants';
import {
  getDirectAuthenticationStatus as getDirectAuthenticationStatusAccessor,
} from '../helpers/directAuthenticationDMPStatus';
import { validateCommand } from '../helpers/remote/validateCommands';
import env from '../../envVariables';
import { extractJSONSendMssMessageParams, extractSendMssMessageParams } from '../helpers/remote/sendMssMessage';
import { extractDeleteDocumentParams, extractJSONDeleteDocumentParams } from '../helpers/remote/deleteDocument';
import {
  b64EncodeUnicode, base64ToArrayBuffer, checkFormatAndBase64Match, generateUniqueId, imapUtf7Encode,
} from '../utils/dataUtils';
import { postMessageToIframeParent } from '../utils/iframe';
import { handleDownloadAllAttachments } from './mssSagas';
import { getDocumentCdaHeaders, getDocumentContentFromUniqueId } from '../helpers/documentUtils';
import { getDocumentInfoFromCache } from './utilsSagas';
import {
  getMssReplyTo, getMssSenderWording, getSendMssEmailAction, mssFolderTypes,
} from '../helpers/mss';
import { formatUploadDocumentParams } from '../helpers/sendDocument';
import { extractPracticeLocationFromState } from '../helpers/practiceLocation';
import { errorActions } from '../errors/errorActions';

const getAccessRights = state => getAccessRightsProps(state);
const getSelectedINS = ({ dmpconnect: { selectedIns } }) => selectedIns;
const getSecretConnection = ({ dmpconnect: { secretConnection } }) => secretConnection;

const getDirectAuthenticationStatus = (state) => {
  const { dmpconnect: { selectedIns } } = state;
  return getDirectAuthenticationStatusAccessor(
    state,
    selectedIns,
  );
};

function getDocumentOptions(state) {
  const {
    dmpConnectPersistedAppConfiguration: {
      forceSchematronsValidation = false,
      ignorePdfA1Transparency = false,
      disabledPdfA1Conversion = false,
    },
  } = state;

  return {
    forceSchematronsValidation,
    ignorePdfA1Transparency,
    disabledPdfA1Conversion,
  };
}

export const isDebugRemoteActive = ({ dmpconnectConnectorConfig }) => (getConfigurationValue(
  'debugActivateRemoteControl',
  dmpconnectConnectorConfig,
));

export const isRemoteControlActive = ({ dmpconnectConnectorConfig }) => (
  getConfigurationValue('debugActivateRemoteControl', dmpconnectConnectorConfig)
  && !getConfigurationValue('forceDesactivateRemoteControl', dmpconnectConnectorConfig)
);
export const getShowNotifications = ({ dmpConnectPersistedAppConfiguration }) => (
  !dmpConnectPersistedAppConfiguration.hideRemoteControlNotifications
);
export const getRemoteDisableExports = ({ dmpConnectPersistedAppConfiguration }) => (
  dmpConnectPersistedAppConfiguration.remoteControlDisableExports
);
export const getRemoteExportVitaleData = ({ dmpConnectPersistedAppConfiguration }) => (
  dmpConnectPersistedAppConfiguration.remoteExportVitaleData
);
export const getRemoteExportChannel = ({ dmpConnectPersistedAppConfiguration }) => (
  dmpConnectPersistedAppConfiguration.remoteExportChannel
);
export const getRemoteExportChannelUrl = ({ dmpConnectPersistedAppConfiguration }) => (
  dmpConnectPersistedAppConfiguration.remoteExportChannelUrl
);


const extractIns = ({ patient }) => {
  const {
    ins: {
      '@root': root,
      '@extension': extension,
    } = {},
  } = patient;

  return {
    insType: root,
    ins: extension + root,
  };
};

const extractJSONIns = ({ patient }) => {
  const {
    ins: {
      root,
      extension,
    } = {},
  } = patient;

  return {
    insType: root,
    ins: extension + root,
  };
};

function* updateToast(requestId, type, keepOnScreen) {
  const showNotifications = yield select(getShowNotifications);
  if (showNotifications === true) {
    toast.update(
      requestId,
      {
        type,
        autoClose: keepOnScreen ? false : 3000,
      },
    );
  }
}

function sendToOtherChannel(response, channel, channelUrl, showToastOnSuccess = false, toastContent = '', type = remoteFormats.XML) {
  let xmlResponse;
  if (type === remoteFormats.JSON) {
    xmlResponse = JSON.stringify({ answer: rename(response, key => key.replace(/^@/, '')) }, null, 4);
  } else {
    const xmlReady = convertJsResultToXmlReadyObject({ answer: response });
    const xml = js2xml(xmlReady, { compact: true, spaces: 4 });
    xmlResponse = `<?xml version="1.0" encoding="utf-8"?>\n${xml}`;
  }

  if (channel.toLowerCase() === remoteResponseChannels.IFRAME.toLowerCase()) {
    postMessageToIframeParent({ type: 'remoteNotification', data: b64EncodeUnicode(xmlResponse) });
    if (showToastOnSuccess) {
      toast(
        toastContent,
        {
          type: 'success',
          position: 'top-right',
          closeOnClick: true,
          autoClose: true,
          hideProgressBar: true,
        },
      );
    }
  } else if (channel.toLowerCase() === remoteResponseChannels.URL.toLowerCase()) {
    console.log('outgoingMessage_post', xmlResponse);
    fetch(channelUrl, {
      method: 'post',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/xml',
      },
      body: xmlResponse,
    })
      .then((res) => {
        if (!res.ok) {
          const erreur = (
            // eslint-disable-next-line react/jsx-filename-extension
            <>
              {' HTTP '}
              {res.status}
              {' '}
              -
              {' '}
              {res.statusText}
            </>
          );
          const message = 'Une erreur s\'est produite pendant l\'export';
          res.json()
            .then((json) => {
              toast((
                <>
                  <div>
                    {json.message || message}
                    <br />
                    {json.erreur || erreur}
                  </div>
                </>
              ),
              {
                type: 'error',
                position: 'top-right',
                closeOnClick: true,
                autoClose: 10000,
                hideProgressBar: true,
                draggable: false,
              });
            })
            .catch(() => {
              toast((
                <>
                  <div>
                    {message}
                    <br />
                    {erreur}
                  </div>
                </>
              ),
              {
                type: 'error',
                position: 'top-right',
                closeOnClick: true,
                autoClose: 10000,
                hideProgressBar: true,
                draggable: false,
              });
            });
        } else if (showToastOnSuccess) {
          toast(
            toastContent,
            {
              type: 'success',
              position: 'top-right',
              closeOnClick: true,
              autoClose: true,
              hideProgressBar: true,
            },
          );
        }
      })
      .catch((e) => {
        toast('Erreur de connection pendant l\'export',
          {
            type: 'error',
            position: 'top-right',
            closeOnClick: true,
            autoClose: 10000,
            hideProgressBar: true,
            draggable: false,
          });
      });
  }
}

function* sendResponse(response, channel, channelUrl, type) {
  if (channel === remoteResponseChannels.CONNECTOR) {
    console.log('outgoingMessage_connector', response);
    yield put(sendRemoteResponse(response));
  } else {
    sendToOtherChannel(response, channel, channelUrl, false, '', type);
  }
}

// function* sendDelayedResponse(response, channel, channelUrl) {
//   if (channel === remoteResponseChannels.CONNECTOR) {
//     yield put(sendRemoteOutMessage(response));
//   } else {
//     sendToOtherChannel(response, channel, channelUrl);
//   }
// }

function* handleError(requestId, responseChannel, channelUrl, type, error, details) {
  const response = generateRemoteFailureResponse(requestId, error, details);

  yield call(sendResponse, response, responseChannel, channelUrl, type);
  yield call(updateToast, requestId, toast.TYPE.ERROR);
}

function* handleLoggedInError(requestId, responseChannel, channelUrl, type) {
  const response = generateRemoteFailureResponse(requestId, {
    i_apiErrorType: errorTypes.RemoteErrors,
    i_apiErrorCode: 'NO_PS_LOGGED_IN',
  });

  yield call(sendResponse, response, responseChannel, channelUrl, type);

  yield call(updateToast, requestId, toast.TYPE.ERROR);
}

function* handleUnknownActionError(requestId, responseChannel, channelUrl, type) {
  const response = generateRemoteFailureResponse(requestId, {
    i_apiErrorType: errorTypes.RemoteErrors,
    i_apiErrorCode: 'UNKNONW_ACTION',
  });

  yield call(sendResponse, response, responseChannel, channelUrl, type);
  yield call(updateToast, requestId, toast.TYPE.ERROR);
}

function* handleRefusedActionError(requestId, responseChannel, channelUrl, type) {
  const response = generateRemoteFailureResponse(requestId, {
    i_apiErrorType: errorTypes.RemoteErrors,
    i_apiErrorCode: 'REFUSED_ACTION',
    s_apiErrorExtendedInformations: 'Action non autorisée',
  });
  yield call(sendResponse, response, responseChannel, channelUrl, type);
  yield call(updateToast, requestId, toast.TYPE.ERROR);
}

function* handleDMPNotFoundError(requestId, responseChannel, channelUrl, type) {
  const response = generateRemoteFailureResponse(requestId, {
    i_apiErrorType: errorTypes.RemoteErrors,
    i_apiErrorCode: 'DMP_NOT_FOUND',
    s_apiErrorExtendedInformations: 'Aucun DMP associé à l’INS fourni.',
  });
  yield call(sendResponse, response, responseChannel, channelUrl, type);
  yield call(updateToast, requestId, toast.TYPE.ERROR);
}

function* handleInvalidInsTypeError(requestId, responseChannel, channelUrl, type) {
  const response = generateRemoteFailureResponse(requestId, {
    i_apiErrorType: errorTypes.RemoteErrors,
    i_apiErrorCode: 'INVALID_INS_TYPE',
    s_apiErrorExtendedInformations: 'Type d’INS inconnu.',
  });
  yield call(sendResponse, response, responseChannel, channelUrl, type);
  yield call(updateToast, requestId, toast.TYPE.ERROR);
}

function* handleGetCurrentDMPRemoteRequest(requestId, responseChannel, channelUrl, type) {
  const directAuthStatus = yield select(getDirectAuthenticationStatus);
  const response = generateGetCurrentDmpResponse(requestId, directAuthStatus);

  yield call(sendResponse, response, responseChannel, channelUrl, type);

  yield call(updateToast, requestId, toast.TYPE.SUCCESS);
}

function* handleFindDMPRemoteRequest(requestId, filters, responseChannel, channelUrl, type) {
  const { accessRights } = yield select(getAccessRights);
  if (!isTransactionAllowed(accessRights, transactions.FIND_PATIENTS) === true) {
    yield call(handleRefusedActionError, requestId, responseChannel, channelUrl, type);
    return;
  }

  const params = type === remoteFormats.JSON
    ? extractJSONFindDmpParams(filters)
    : extractFindDmpParams(filters);

  yield put(remoteFindPatients(params));

  const result = yield take([
    dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_FIND_PATIENTS_SECTION),
    dmpRemoteCommandFailureContextualizedType(apiSections.REMOTE_FIND_PATIENTS_SECTION),
  ]);

  let response;
  if (result && result.type === dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_FIND_PATIENTS_SECTION)) {
    const { data: { Patients = [] } } = result;

    response = generateFindDmpResponse(requestId, Patients);

    yield call(updateToast, requestId, toast.TYPE.SUCCESS);
  } else {
    // gestion erreur
    const { data: error } = result;
    response = generateRemoteFailureResponse(requestId, error);
    yield call(updateToast, requestId, toast.TYPE.ERROR);
  }

  yield call(sendResponse, response, responseChannel, channelUrl, type);
}

function* handleTestDmpExistenceRemoteRequest(requestId, confidentialityLevel, patient, responseChannel, channelUrl, type) {
  const { accessRights } = yield select(getAccessRights);
  if (!isTransactionAllowed(accessRights, transactions.AUTHORIZATION_CHECK) === true) {
    yield call(handleRefusedActionError, requestId, responseChannel, channelUrl, type);
    return;
  }

  const params = type === remoteFormats.JSON ? extractJSONIns(patient) : extractIns(patient);

  if (!Object.values(insTypes).includes(params.insType.toUpperCase())) {
    yield call(handleInvalidInsTypeError, requestId, responseChannel, channelUrl, type);
    return;
  }

  const { ins } = params;

  const secretConnection = yield select(getSecretConnection);

  yield put(getAction(
    commands.setConfidentialityLevel,
    apiSections.CONFIDENTIALITY_LEVEL_SECTION,
    { i_enableSecretConnection: confidentialityLevel },
    { subSection: ins },
  ));
  const confidentialityLevelResult = yield take([
    dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
    dmpCommandFailureContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
    dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION_REST),
  ]);

  if (
    confidentialityLevelResult
    && confidentialityLevelResult.type === dmpRemoteCommandFailureContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION)
  ) {
    yield call(handleError, requestId, responseChannel, channelUrl, type, confidentialityLevelResult.data);
    return;
  }

  yield put(remoteGetDirectAuthenticationDMPStatus(ins));
  let result;
  const { accessRights: { psId } } = yield select(getAccessRightsProps);
  const subSectionID = `${ins}/${psId}`;
  while (result === undefined) {
    const directResult = yield take([
      dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      dmpRemoteCommandFailureContextualizedType(apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      dmpCommandFailureContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
    ]);
    const { context: { subSection } } = directResult;

    if (subSection === subSectionID) {
      result = directResult;
    }
  }

  let response;
  if (
    result
      && [
        dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
        dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      ].includes(result.type)
  ) {
    const { data } = result;

    response = generateTestDmpExistenceResponse(requestId, data);

    yield call(updateToast, requestId, toast.TYPE.SUCCESS);
  } else {
    // gestion erreur
    const { data: error } = result;
    response = generateRemoteFailureResponse(requestId, error);
    yield call(updateToast, requestId, toast.TYPE.ERROR);
  }


  yield call(sendResponse, response, responseChannel, channelUrl, type);

  yield put(getAction(
    commands.setConfidentialityLevel,
    apiSections.CONFIDENTIALITY_LEVEL_SECTION,
    { i_enableSecretConnection: secretConnection },
    { subSection: ins },
  ));
}

function* handleOpenDmp(requestId, confidentialityLevel, patient, responseChannel, channelUrl, type) {
  const { accessRights } = yield select(getAccessRights);

  const params = type === remoteFormats.JSON ? extractJSONIns(patient) : extractIns(patient);

  if (!Object.values(insTypes).includes(params.insType.toUpperCase())) {
    yield call(handleInvalidInsTypeError, requestId, responseChannel, channelUrl, type);
    return;
  }

  const { ins } = params;

  yield put(getAction(
    commands.setConfidentialityLevel,
    apiSections.CONFIDENTIALITY_LEVEL_SECTION,
    { i_enableSecretConnection: confidentialityLevel },
    {
      subSection: ins,
    },
  ));
  const confidentialityLevelResult = yield take([
    dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
    dmpCommandFailureContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
    dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION_REST),
  ]);

  if (
    confidentialityLevelResult
    && confidentialityLevelResult.type === dmpRemoteCommandFailureContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION)
  ) {
    yield call(handleError, requestId, responseChannel, channelUrl, type, confidentialityLevelResult.data);
    return;
  }

  yield put(remoteGetDirectAuthenticationDMPStatus(ins));
  let result;
  const { accessRights: { psId } } = yield select(getAccessRightsProps);
  const subSectionID = `${ins}/${psId}`;
  while (result === undefined) {
    const directResult = yield take([
      dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      dmpRemoteCommandFailureContextualizedType(apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      dmpCommandFailureContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
    ]);
    const { context: { subSection } } = directResult;

    if (subSection === subSectionID) {
      result = {
        ...directResult,
        type: dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      };
    }
  }

  let response;

  if (result && result.type === dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION)) {
    const {
      data: {
        ExistingTestAnswer: {
          i_dmpStatus: dmpStatus,
          // AdminData: {
          //   s_patientGivenName: given,
          //   s_patientName: name,
          // } = {},
        } = {},
      },
    } = result;

    // dmp n'existe pas
    if (dmpStatus === dmpStatuses.DMPNotFound || dmpStatus === dmpStatuses.DMPIsClosed) {
      let extraInfos = '';
      if (dmpStatus === dmpStatuses.DMPNotFound) {
        extraInfos = 'Aucun DMP associé à l’INS fourni.';
      }
      if (dmpStatus === dmpStatuses.DMPIsClosed) {
        extraInfos = 'Le DMP associé à l’INS fourni est fermé';
      }
      yield call(
        handleError,
        requestId,
        responseChannel,
        channelUrl,
        type,
        {
          i_apiErrorType: errorTypes.RemoteErrors,
          i_apiErrorCode: 'DMP_NOT_FOUND',
          s_apiErrorExtendedInformations: extraInfos,
        },
      );
      return;
    }

    // dmp déjà ouvert ?
    const currentPathname = yield select(getCurrentPathname);
    if (currentPathname.indexOf(`/dmp/${encodeIns(ins)}`) !== -1) {
      response = {
        '@RelatesTo': requestId,
        '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
      };
      yield call(updateToast, requestId, toast.TYPE.SUCCESS);
    } else {
      // yield put(remoteGetOpenDMPConsent({ name, given }, ins));
      // const consentAnswer = yield take(dmpconnectRemoteActionConstants.DMPC_REMOTE_ANSWER_OPEN_DMP_CONSENT);
      // const { consent } = consentAnswer;
      // if (!consent) {
      //   response = generateRemoteFailureResponse(requestId, {
      //     i_apiErrorType: errorTypes.RemoteErrors,
      //     i_apiErrorCode: 'REJECTED_BY_USER',
      //     s_apiErrorExtendedInformations: 'L’utilisateur a rejeté l’action demandée.',
      //   });
      //   yield call(updateToast, requestId, toast.TYPE.ERROR);
      // } else {
      yield put(selectINS(ins));

      const dmpLandingPage = yield select(getDmpLandingPage);
      const url = getDocumentRedirectUrl(accessRights, ins, dmpLandingPage);
      yield put(push(url));

      response = {
        '@RelatesTo': requestId,
        '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
      };

      yield call(updateToast, requestId, toast.TYPE.SUCCESS);
      // }
    }
  } else {
    response = generateRemoteFailureResponse(requestId, result.data);
    yield call(updateToast, requestId, toast.TYPE.ERROR);
  }

  yield call(sendResponse, response, responseChannel, channelUrl, type);
}

function* handleCloseDmpSession(requestId, responseChannel, channelUrl, type) {
  let response;

  const selectedINS = yield select(getSelectedINS);

  if (selectedINS && selectedINS !== -1) {
    // yield put(remoteGetCloseDMPConsent());
    // const consentAnswer = yield take(dmpconnectRemoteActionConstants.DMPC_REMOTE_ANSWER_CLOSE_DMP_CONSENT);
    // const { consent } = consentAnswer;
    // if (!consent) {
    //   response = generateRemoteFailureResponse(requestId, {
    //     i_apiErrorType: errorTypes.RemoteErrors,
    //     i_apiErrorCode: 'REJECTED_BY_USER',
    //     s_apiErrorExtendedInformations: 'L’utilisateur a rejeté l’action demandée.',
    //   });
    //   yield call(updateToast, requestId, toast.TYPE.ERROR);
    // } else {
    yield put(selectINS(null));
    yield put(push('/home'));

    response = {
      '@RelatesTo': requestId,
      '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
    };

    yield call(updateToast, requestId, toast.TYPE.SUCCESS);
    // }
  } else {
    response = generateRemoteFailureResponse(requestId, {
      i_apiErrorType: errorTypes.RemoteErrors,
      i_apiErrorCode: 'DMP_NOT_CONNECTED',
      s_apiErrorExtendedInformations: 'Pas de DMP ouvert',
    });
    yield call(updateToast, requestId, toast.TYPE.ERROR);
  }

  yield call(sendResponse, response, responseChannel, channelUrl, type);
}

function* handleSubmitDocument(requestId, confidentialityLevel, data, responseChannel, channelUrl, type) {
  yield put(clearRemoteSection(apiSections.REMOTE_SEND_DOCUMENT));
  yield put(clearRemoteSection(apiSections.REMOTE_SUBMIT_DOCUMENT));

  const { accessRights } = yield select(getAccessRights);
  if (!canUpload(accessRights)) {
    yield call(handleRefusedActionError, requestId, responseChannel, channelUrl, type);
    return;
  }

  const secretConnection = yield select(getSecretConnection);

  const { params, visibility, insType } = type === remoteFormats.JSON
    ? extractJSONSubmitDocumentParams(data)
    : extractSubmitDocumentParams(data);

  if (Number(params.sendInBackground) === 1 || params.sendInBackground === true) {
    if (Number(params.modal) === 1 || params.modal === true) {
      yield put(toggleLoadingBackdrop(true, params.modalMessage || 'Traitement d\'une commande en cours'));
    }
  }


  // check cumul de visibilité
  const { i_cumulMasquePsPatient, i_minorFunctionManagement } = yield select(getDmpParameterFromState);
  if (
    (visibility.invisiblePatient && visibility.masquePS && i_cumulMasquePsPatient === 0)
    || (visibility.invisibleRepresentant && visibility.masquePS && i_minorFunctionManagement === 0)
  ) {
    const i_apiErrorCode = 'BAD_CONFIDENTIALITY';
    let s_apiErrorExtendedInformations = 'Impossible de définir ce niveau de confidentialité pour le document. ';
    if (visibility.invisiblePatient && visibility.masquePS && i_cumulMasquePsPatient === 0) {
      s_apiErrorExtendedInformations += 'masquePS et invisiblePatient définis conjointement à vrai. ';
    }
    if (visibility.invisibleRepresentant && visibility.masquePS && i_minorFunctionManagement === 0) {
      s_apiErrorExtendedInformations = 'masquePS et invisibleRepresentant définis conjointement à vrai).';
    }
    yield call(
      handleError,
      requestId,
      responseChannel,
      channelUrl,
      type,
      {
        i_apiErrorType: errorTypes.RemoteErrors,
        i_apiErrorCode,
        s_apiErrorExtendedInformations,
      },
    );
    yield call(updateToast, requestId, toast.TYPE.ERROR);
    return;
  }

  const fileType = yield call(FileType.fromBuffer, base64ToArrayBuffer(params.base64));
  if (fileType) {
    const mismatch = (
      (fileType.mime === 'application/xml' && ![...documentFormatsCda, documentFormats.textPlain].includes(params.documentFormat))
      || (fileType.mime !== 'application/xml' && fileType.mime !== documentFormatTypeMimes[params.documentFormat])
    );

    if (mismatch) {
      yield call(
        handleError,
        requestId,
        responseChannel,
        channelUrl,
        type,
        {
          i_apiErrorType: errorTypes.RemoteErrors,
          i_apiErrorCode: 'INVALID_FORMAT',
          s_apiErrorExtendedInformations: 'Le contenu du document ne correspond pas au format de document fourni.',
        },
      );
      yield call(updateToast, requestId, toast.TYPE.ERROR);
      return;
    }
  }

  if (Number(params.sendInBackground) === 1 || params.sendInBackground === true) {
    if (!params.creationDate) {
      params.creationDate = moment().format('DD/MM/YYYY');
    }
    if (!params.serviceStartDate) {
      params.serviceStartDate = moment().format('DD/MM/YYYY');
    }
    if (!params.serviceStopDate) {
      params.serviceStopDate = moment().format('DD/MM/YYYY');
    }
  }

  // check structured docs
  if (documentFormatsCda.includes(params.documentFormat)) {
    yield put(remoteValidateCDADocuments(requestId, params));
    const document = yield take([
      dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_VALIDATE_DOCUMENT),
      dmpRemoteCommandFailureContextualizedType(apiSections.REMOTE_VALIDATE_DOCUMENT),
    ]);

    if (document.type === dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_VALIDATE_DOCUMENT)) {
      const { Documents } = document.data;
      const { s_contentInBase64, s_cdaHeadersInBase64, s_cdaInBase64 } = Documents[0];
      Object.assign(params, {
        structuredHtml: s_contentInBase64,
        structuredHeader: s_cdaHeadersInBase64,
        stylesheetApplied: hasCustomStyleSheetApplied(s_cdaInBase64),
      });
    } else {
      let errorExtendInfo = 'Invalid cda content';
      let details;
      if (document.data.s_apiErrorDescription) {
        errorExtendInfo = document.data.s_apiErrorDescription;
        details = document.data.s_apiErrorExtendedInformations;
      }
      yield call(
        handleError,
        requestId,
        responseChannel,
        channelUrl,
        type,
        {
          i_apiErrorType: errorTypes.RemoteErrors,
          i_apiErrorCode: 'INVALID_FORMAT',
          s_apiErrorExtendedInformations: errorExtendInfo,
        },
        details,
      );
      yield call(updateToast, requestId, toast.TYPE.ERROR);
      return;
    }
  }

  if (!Object.values(insTypes).includes(insType.toUpperCase())) {
    yield call(handleInvalidInsTypeError, requestId, responseChannel, channelUrl, type);
    return;
  }

  yield put(getAction(
    commands.setConfidentialityLevel,
    apiSections.CONFIDENTIALITY_LEVEL_SECTION,
    { i_enableSecretConnection: confidentialityLevel },
    { subSection: params.ins },
  ));
  const confidentialityLevelResult = yield take([
    dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
    dmpCommandFailureContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
    dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION_REST),
  ]);

  if (
    confidentialityLevelResult
    && confidentialityLevelResult.type === dmpRemoteCommandFailureContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION)
  ) {
    yield call(handleError, requestId, responseChannel, channelUrl, type, confidentialityLevelResult.data);
    return;
  }

  let response;

  if (!params.Identity) {
    yield put(remoteGetDirectAuthenticationDMPStatus(params.ins));
    let result;
    const { accessRights: { psId } } = yield select(getAccessRightsProps);
    const subSectionID = `${params.ins}/${psId}`;
    while (result === undefined) {
      const directResult = yield take([
        dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
        dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
        dmpRemoteCommandFailureContextualizedType(apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
        dmpCommandFailureContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      ]);
      const { context: { subSection } } = directResult;

      if (subSection === subSectionID) {
        result = {
          ...directResult,
          type: dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
        };
      }
    }
    // vérifier l'autorisation d'accès
    const {
      data: {
        ExistingTestAnswer: {
          i_dmpStatus: dmpStatus,
        } = {},
      },
    } = result;


    // dmp n'existe pas
    if (dmpStatus !== dmpStatuses.DMPExist) {
      yield call(handleDMPNotFoundError, requestId, responseChannel, channelUrl, type);
      yield put(getAction(
        commands.setConfidentialityLevel,
        apiSections.CONFIDENTIALITY_LEVEL_SECTION,
        { i_enableSecretConnection: secretConnection },
        { subSection: 'all' },
      ));
      return;
    }
  }

  if (Number(params.sendInBackground) === 1 || params.sendInBackground === true) {
    const {
      forceSchematronsValidation,
      ignorePdfA1Transparency,
      disabledPdfA1Conversion,
    } = yield select(getDocumentOptions);

    const {
      typeCode,
      data: base64Content,
      title,
      description,
      format,
      documentVisibility,
      healthcareSetting,
      creationDate,
      replaceDocumentId,
      serviceStartDate,
      serviceStopDate,
      setIdRoot,
      setIdExtension,
      versionNumber,
    } = formatUploadDocumentParams(params);

    yield put(remoteSendDocument({
      ins: params.ins,

      base64Content,
      stylesheetBase64: params.stylesheetBase64,
      title,
      description,
      category: typeCode,
      visibility: documentVisibility,
      format,
      healthcareSetting,
      creationDate,
      serviceStartDate,
      serviceStopDate,
      replacedDocumentId: replaceDocumentId,
      setIdRoot,
      setIdExtension,
      versionNumber,

      eventCodes: params.eventCodes,
      Informants: params.informants,
      Performer: params.performer,
      TreatingPhycisian: params.treatingPhysician,
      AdditionalAuthors: params.additionalAuthors,
      IntendedRecipients: params.intendedRecipients,

      retrieveDocumentUuid: params.retrieveDocumentUuid,
      getDocumentContent: params.getDocumentContent,

      uniqueId: params.uniqueId,
      Identity: params.Identity,

      forceSchematronsValidation,
      ignorePdfA1Transparency: params.ignorePdfA1Transparency !== undefined ? params.ignorePdfA1Transparency : ignorePdfA1Transparency,
      disabledPdfA1Conversion: params.disabledPdfA1Conversion !== undefined ? params.disabledPdfA1Conversion : disabledPdfA1Conversion,

      submitEngine: params.submitEngine,
      AdditionalPatientIdentifiers: params.AdditionalPatientIdentifiers,
    }));
  } else {
    yield put(remoteSubmitDoc(requestId, params));
  }

  const resultSubmit = yield take([
    dmpconnectRemoteActionConstants.DMPC_REMOTE_SUBMIT_DOC_REFUSE,
    dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_SEND_DOCUMENT),
    dmpRemoteCommandFailureContextualizedType(apiSections.REMOTE_SEND_DOCUMENT),
  ]);

  if (resultSubmit && resultSubmit.type === dmpconnectRemoteActionConstants.DMPC_REMOTE_SUBMIT_DOC_REFUSE) {
    response = generateRemoteFailureResponse(requestId, {
      i_apiErrorType: errorTypes.RemoteErrors,
      i_apiErrorCode: 'REJECTED_BY_USER',
      s_apiErrorExtendedInformations: 'L’utilisateur a rejeté l’action demandée.',
    });
    yield call(updateToast, requestId, toast.TYPE.ERROR);
  } else if (resultSubmit && resultSubmit.type === dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_SEND_DOCUMENT)) {
    const {
      data: {
        s_uniqueId, s_uuid, UniqueIds, Uuids, DocumentContent,
      },
    } = resultSubmit;
    const uniqueId = s_uniqueId || (UniqueIds && UniqueIds.length > 0 ? UniqueIds[0] : undefined);
    const uuid = s_uuid || (Uuids && Uuids.length > 0 ? Uuids[0] : undefined);

    response = {
      '@RelatesTo': requestId,
      '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
      document: {
        uniqueId,
        uuid,
        cdaContent: DocumentContent ? rename(DocumentContent, key => key.replace(/^s_|^i_/, '')) : undefined,
      },
    };

    yield call(updateToast, requestId, toast.TYPE.SUCCESS);
  } else {
    const { data: error } = resultSubmit;
    response = generateRemoteFailureResponse(requestId, error);
    yield call(updateToast, requestId, toast.TYPE.ERROR);
  }

  yield put(remoteSubmitDoc(null, null));
  yield call(sendResponse, response, responseChannel, channelUrl, type);
  yield put(getAction(
    commands.setConfidentialityLevel,
    apiSections.CONFIDENTIALITY_LEVEL_SECTION,
    { i_enableSecretConnection: secretConnection },
    { subSection: 'all' },
  ));
  if (Number(params.modal) === 1 || params.modal === true) {
    yield put(toggleLoadingBackdrop(false, ''));
  }
}

function* handleDeleteDocument(requestId, confidentialityLevel, data, responseChannel, channelUrl, type) {
  const secretConnection = yield select(getSecretConnection);

  const {
    ins, uniqueId, uuid, practice, submitEngine,
  } = type === remoteFormats.JSON ? extractJSONDeleteDocumentParams(data) : extractDeleteDocumentParams(data);

  yield put(getAction(
    commands.setConfidentialityLevel,
    apiSections.CONFIDENTIALITY_LEVEL_SECTION,
    { i_enableSecretConnection: confidentialityLevel },
    { subSection: ins },
  ));
  const confidentialityLevelResult = yield take([
    dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
    dmpCommandFailureContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
    dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION_REST),
  ]);

  if (
    confidentialityLevelResult
    && confidentialityLevelResult.type === dmpRemoteCommandFailureContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION)
  ) {
    yield call(handleError, requestId, responseChannel, channelUrl, type, confidentialityLevelResult.data);
    return;
  }

  yield put(remoteDeleteDocument(formatDeleteDocumentParams(uniqueId, uuid, ins, practice, submitEngine)));
  const deleteResult = yield take([
    dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_DELETE_DOCUMENT),
    dmpRemoteCommandFailureContextualizedType(apiSections.REMOTE_DELETE_DOCUMENT),
  ]);

  let response;
  if (deleteResult && deleteResult.type === dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_DELETE_DOCUMENT)) {
    response = {
      '@RelatesTo': requestId,
      '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
    };

    yield call(updateToast, requestId, toast.TYPE.SUCCESS);
  } else {
    const { data: error } = deleteResult;
    response = generateRemoteFailureResponse(requestId, error);
    yield call(updateToast, requestId, toast.TYPE.ERROR);
  }
  yield call(sendResponse, response, responseChannel, channelUrl, type);
  yield put(getAction(
    commands.setConfidentialityLevel,
    apiSections.CONFIDENTIALITY_LEVEL_SECTION,
    { i_enableSecretConnection: secretConnection },
    { subSection: 'all' },
  ));
}

function* handleGetCertifiedIdentity(requestId, confidentialityLevel, data, responseChannel, channelUrl, type) {
  const { accessRights } = yield select(getAccessRights);
  if (!isTransactionAllowed(accessRights, transactions.CERTIFIED_IDENTITY) === true) {
    yield call(handleRefusedActionError, requestId, responseChannel, channelUrl, type);
    return;
  }

  const params = type === remoteFormats.JSON ? extractJSONGetCertifiedIdentityParams(data) : extractGetCertifiedIdentityParams(data);

  if (!Object.values(insTypes).includes(params.insType.toUpperCase())) {
    yield call(handleInvalidInsTypeError, requestId, responseChannel, channelUrl, type);
    return;
  }

  let response;
  const secretConnection = yield select(getSecretConnection);

  if (params === false) {
    response = generateRemoteFailureResponse(requestId, {
      i_apiErrorType: errorTypes.RemoteErrors,
      i_apiErrorCode: 'NOT_ENOUGH_PARAMS',
      s_apiErrorExtendedInformations: 'Nombre de paramètres insuffisant.',
    });
    yield call(updateToast, requestId, toast.TYPE.ERROR);
  } else {
    yield put(getAction(
      commands.setConfidentialityLevel,
      apiSections.CONFIDENTIALITY_LEVEL_SECTION,
      { i_enableSecretConnection: confidentialityLevel },
      { subSection: params.s_ins },
    ));
    const confidentialityLevelResult = yield take([
      dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
      dmpCommandFailureContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
      dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION_REST),
    ]);

    if (
      confidentialityLevelResult
      && confidentialityLevelResult.type === dmpRemoteCommandFailureContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION)
    ) {
      yield call(handleError, requestId, responseChannel, channelUrl, type, confidentialityLevelResult.data);
      return;
    }

    yield put(remoteGetCertifiedIdentity(params));

    const result = yield take([
      dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_CERTIFIFIED_IDENTITY_SECTION),
      dmpRemoteCommandFailureContextualizedType(apiSections.REMOTE_CERTIFIFIED_IDENTITY_SECTION),
    ]);

    if (result && result.type === dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_CERTIFIFIED_IDENTITY_SECTION)) {
      const { data: resultData = {} } = result;

      response = generateGetCertifiedIdentityResponse(requestId, resultData);

      yield call(updateToast, requestId, toast.TYPE.SUCCESS);
    } else {
      const { data: error } = result;
      response = generateRemoteFailureResponse(requestId, error);
      yield call(updateToast, requestId, toast.TYPE.ERROR);
    }
  }
  yield call(sendResponse, response, responseChannel, channelUrl, type);
  yield put(getAction(
    commands.setConfidentialityLevel,
    apiSections.CONFIDENTIALITY_LEVEL_SECTION,
    { i_enableSecretConnection: secretConnection },
    { subSection: 'all' },
  ));
}

function* handleGetEfficienceVersion(requestId, responseChannel, channelUrl, type) {
  const response = generateGetEfficienceVersionResponse(requestId);

  yield call(sendResponse, response, responseChannel, channelUrl, type);

  yield call(updateToast, requestId, toast.TYPE.SUCCESS);
}

function* handleGetStatus(requestId, responseChannel, channelUrl, type) {
  const isLoggedIn = yield select(isUserLoggedInAccessor);
  const mssActive = yield select(isMssActive);
  const mssEmail = yield select(getMssEmail);
  const esUser = yield select(getEsUser);
  const practiceSetting = yield select(getPracticeSetting);
  const healthcareSetting = yield select(getHealthcareSetting);

  const directAuthStatus = yield select(getDirectAuthenticationStatus);

  const { accessRights: { psId, authenticationType } } = yield select(getAccessRightsProps);
  const definedPracticeLocation = yield select(extractPracticeLocationFromState, psId);

  const readers = yield select((state) => {
    const { dmpconnect: { [apiSections.PCSC_READERS_SECTION]: { Readers = [] } = {} } = {} } = state;
    return Readers;
  });

  let response = {
    '@RelatesTo': requestId,
    '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
    version: env.REACT_APP_VERSION,
    hpConnected: type === remoteFormats.JSON ? isLoggedIn : String(isLoggedIn),
    mss: {
      active: !!mssActive,
      email: mssEmail,
    },
  };

  if (readers && readers.length > 0) {
    response = {
      ...response,
      readers: readers.map(r => ({
        name: r.s_name,
        accessMode: r.i_accessMode,
        accessModeDescription: r.s_accessMode,
        cardType: r.i_slotType,
        cardTypeDescription: r.s_slotType,
      })),
    };
  }

  if (isLoggedIn) {
    if (authenticationType === authenticationTypes.DIRECT) {
      response = {
        ...response,
        hpInfos: isLoggedIn
          ? {
            rpps: psId,
            practiceSetting,
            healthcareSetting,
            ...definedPracticeLocation,
          }
          : undefined,
      };
    } else {
      response = {
        ...response,
        hpInfos: {
          ...esUser,
          practiceSetting,
          healthcareSetting,
        },
      };
    }

    response = { ...response, currentDmp: { ...generateGetCurrentDmpBody(requestId, directAuthStatus) } };
  }

  yield call(sendResponse, response, responseChannel, channelUrl, type);

  yield call(updateToast, requestId, toast.TYPE.SUCCESS);
}

function getMssSendEmailParams(state) {
  const {
    dmpconnectMSSConfiguration: {
      mssApiType,
    },
    dmpconnectUser: { esUser },
    dmpconnectESConfiguration,
  } = state;

  return {
    hpAuthenticationMode: getConfigurationValue('hpAuthenticationMode', dmpconnectESConfiguration) || 8,
    mssEmail: getMssEmail(state),
    apiType: getApiType(state),
    esUser,
    healthcareSetting: getHealthcareSetting(state),
    mssApiType,
    mssSenderWording: getMssSenderWording(state),
    mssReplyTo: getMssReplyTo(state),
    ...getDocumentOptions(state),
  };
}

const getMssFolders = (state, email) => {
  const {
    mssMessages: {
      [email]: {
        MssFolders = [],
      } = {},
    } = {},
  } = state;
  return MssFolders;
};

function* handleGetMssNbUnreadMessages(requestId, request, responseChannel, channelUrl, type) {
  const mssActive = yield select(isMssActive);
  const mssEmailConfig = yield select(getMssEmail);
  const mssApiType = yield select(({ dmpconnectMSSConfiguration }) => dmpconnectMSSConfiguration.mssApiType);
  let response;

  if (!mssActive) {
    yield call(handleError, requestId, responseChannel, channelUrl, type, {
      i_apiErrorType: errorTypes.RemoteErrors,
      i_apiErrorCode: 'REFUSED_ACTION',
      s_apiErrorExtendedInformations: 'Le module MSS n\'est pas activé',
    });
    yield call(updateToast, requestId, toast.TYPE.ERROR);
    yield put(toggleLoadingBackdrop(false, ''));
    return;
  }

  if (!mssEmailConfig) {
    yield call(handleError, requestId, responseChannel, channelUrl, type, {
      i_apiErrorType: errorTypes.RemoteErrors,
      i_apiErrorCode: 'REFUSED_ACTION',
      s_apiErrorExtendedInformations: 'L\'adresse email de l\'utilisateur n\'est pas configurée',
    });
    yield call(updateToast, requestId, toast.TYPE.ERROR);
    yield put(toggleLoadingBackdrop(false, ''));
    return;
  }

  const {
    folderId: {
      value: folder,
    } = {},
  } = request;

  // get INBOX folder content
  let mssFolders = yield select(getMssFolders, mssEmailConfig);

  if (!mssFolders || mssFolders.length === 0) {
    // get folders
    yield put(getAction(
      commands.getFoldersMSS,
      apiSections.MSS_GET_FOLDERS,
      mssEmailConfig,
      {
        subConfig: mssApiType,
        contextExtra: { mssApiType, forceAction: errorActions.NONE },
        synchronous: true,
      },
    ));

    const foldersResults = yield take([
      dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MSS_FOLDERS,
      dmpCommandFailureContextualizedType(apiSections.MSS_GET_FOLDERS),
    ]);
    if (foldersResults.type === dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MSS_FOLDERS) {
      mssFolders = yield select(getMssFolders, mssEmailConfig);
    } else {
      response = generateRemoteFailureResponse(
        requestId,
        {
          i_apiErrorType: errorTypes.MssErrors,
          s_apiErrorExtendedInformations: 'Impossible de récupérer les dossiers de la boite mail',
        },
        foldersResults,
      );
      yield call(updateToast, requestId, toast.TYPE.ERROR);
    }
  }

  let inbox = mssFolders.find(f => f.type === mssFolderTypes.INBOX);
  if (folder) {
    inbox = mssFolders.find(f => String(f.id) === (mssApiType === mssSubTypes.IMAP ? imapUtf7Encode(folder) : String(folder)));
  }

  if (inbox) {
    yield put(getAction(
      commands.getSearchMessagesMSS,
      apiSections.MSS_SEARCH_MESSAGES,
      { email: mssEmailConfig, folderId: inbox.id },
      {
        synchronous: true,
        subSection: inbox.id,
        subConfig: mssApiType,
        contextExtra: { mssApiType, forceAction: errorActions.NONE },
      },
    ));
    let result;
    while (result === undefined) {
      const searchResult = yield take([
        dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MSS_SYNCMESSAGES,
        dmpCommandFailureContextualizedType(apiSections.MSS_SEARCH_MESSAGES),
      ]);

      const {
        context: { subSection } = {}, // in case of dmpCommandFailureContextualizedType(apiSections.MSS_SEARCH_MESSAGES)
        messages: { folderId } = {}, // in case of dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MSS_SYNCMESSAGES
      } = searchResult;

      if (subSection === inbox.id || folderId === inbox.id) {
        result = searchResult;
      }
    }

    if (result.type === dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MSS_SYNCMESSAGES) {
      const { messages: { modifiedMessages = [], allMessages = [] } } = result;
      const unreadMessages = [...modifiedMessages, ...allMessages].filter((message) => {
        const { flags: { unread = false } = {} } = message;
        return unread;
      });

      response = {
        '@RelatesTo': requestId,
        '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
        email: mssEmailConfig,
        unreadMessages: unreadMessages.length,
      };
      yield call(updateToast, requestId, toast.TYPE.SUCCESS);
    } else {
      response = generateRemoteFailureResponse(requestId, result.data);
      yield call(updateToast, requestId, toast.TYPE.ERROR);
    }
  }

  yield call(sendResponse, response, responseChannel, channelUrl, type);
}

function* handleSendMssMessage(requestId, request, responseChannel, channelUrl, type) {
  const mssActive = yield select(isMssActive);
  const mssEmailConfig = yield select(getMssEmail);
  const mssApiType = yield select(({ dmpconnectMSSConfiguration }) => dmpconnectMSSConfiguration.mssApiType);
  let response;

  if (!mssActive) {
    yield call(handleError, requestId, responseChannel, channelUrl, type, {
      i_apiErrorType: errorTypes.RemoteErrors,
      i_apiErrorCode: 'REFUSED_ACTION',
      s_apiErrorExtendedInformations: 'Le module MSS n\'est pas activé',
    });
    yield call(updateToast, requestId, toast.TYPE.ERROR);
    yield put(toggleLoadingBackdrop(false, ''));
    return;
  }

  if (!mssEmailConfig) {
    yield call(handleError, requestId, responseChannel, channelUrl, type, {
      i_apiErrorType: errorTypes.RemoteErrors,
      i_apiErrorCode: 'REFUSED_ACTION',
      s_apiErrorExtendedInformations: 'L\'adresse email de l\'utilisateur n\'est pas configurée',
    });
    yield call(updateToast, requestId, toast.TYPE.ERROR);
    yield put(toggleLoadingBackdrop(false, ''));
    return;
  }

  const params = type === remoteFormats.JSON
    ? extractJSONSendMssMessageParams(request)
    : extractSendMssMessageParams(request);

  if (Number(params.sendInBackground) === 1 || params.sendInBackground === true) {
    if (Number(params.modal) === 1 || params.modal === true) {
      yield put(toggleLoadingBackdrop(true, params.modalMessage || 'Traitement d\'une commande en cours'));
    }
  }

  if (params.attachments && params.attachments.length > 0) {
    for (let i = 0; i < params.attachments.length; i += 1) {
      const att = params.attachments[i];
      const formatsMatched = yield call(checkFormatAndBase64Match, att.documentFormat, att.fileContentInBase64);
      if (!formatsMatched) {
        yield call(
          handleError,
          requestId,
          responseChannel,
          channelUrl,
          type,
          {
            i_apiErrorType: errorTypes.RemoteErrors,
            i_apiErrorCode: 'INVALID_FORMAT',
            s_apiErrorExtendedInformations: `Le contenu du document "${att.documentTitle}" ne correspond pas au format de document fourni.`,
          },
        );
        yield call(updateToast, requestId, toast.TYPE.ERROR);
        return;
      }
    }
  }

  yield put(clearSection(apiSections.MSS_SEND_SMTP_EMAIL));

  if (Number(params.sendInBackground) === 1 || params.sendInBackground === true) {
    const {
      apiType,
      esUser,
      mssEmail,
      hpAuthenticationMode,
      mssSenderWording,
      mssReplyTo,
      forceSchematronsValidation,
      ignorePdfA1Transparency,
      disabledPdfA1Conversion,
      disableIheXdmPdfTitlePage,
      disableIheXdmPdfDataMatrixBlock,
      getDocumentContent,
    } = yield select(getMssSendEmailParams);

    const action = getSendMssEmailAction(
      mssApiType,
      apiType,
      {
        sender: mssEmail,
        title: params.title,
        messageContent: params.messageContent,
        recipients: params.recipients,
        cc: params.cc,
        bcc: params.bcc,
        attachments: params.attachments,
        otherAttachments: params.otherAttachments,
        isHtml: true,
        sendReceiptNotification: !!params.notificationReceiver,
        notificationReceivers: params.notificationReceiver,
        receiptNotificationType: params.notificationReceiversType,
        replyTo: params.replyTo || mssReplyTo,
        inReplyToMessageIds: params.inReplyToMessageIds,
        references: params.references,
        senderWording: params.senderWording || mssSenderWording,
        messageId: params.messageId || generateUniqueId(),

        forceSchematronsValidation,
        ignorePdfA1Transparency: params.ignorePdfA1Transparency !== undefined ? params.ignorePdfA1Transparency : ignorePdfA1Transparency,
        disabledPdfA1Conversion: params.disabledPdfA1Conversion !== undefined ? params.disabledPdfA1Conversion : disabledPdfA1Conversion,

        disableIheXdmPdfTitlePage,
        disableIheXdmPdfDataMatrixBlock,
        getDocumentContent,

        Identity: params.insiIdentity,
        insIsNotQualified: params.insIsNotQualified,
        AdditionalPatientIdentifiers: params.AdditionalPatientIdentifiers,
      },
      esUser,
      hpAuthenticationMode,
    );
    yield put(action);
  } else {
    yield put(resetMssEmailContent(params.attachments.length > 0));
    yield put(setMssEmailContent({ ...params, isRemote: true }));
    yield put(setShowMssPopup(true));
    if (responseChannel !== remoteResponseChannels.CONNECTOR) {
      response = {
        '@RelatesTo': requestId,
        '@status': 'urn:ihe:iti:2007:ResponseStatusType:PartialSuccess',
      };

      yield call(updateToast, requestId, toast.TYPE.SUCCESS, true);
      yield call(sendResponse, response, responseChannel, channelUrl, type);
    }
  }


  const sendResult = yield take([
    dmpconnectRemoteActionConstants.DMPC_REMOTE_MSS_SEND_MESSAGE_REFUSED,
    dmpCommandSuccessContextualizedType(apiSections.MSS_SEND_SMTP_EMAIL),
    dmpCommandFailureContextualizedType(apiSections.MSS_SEND_SMTP_EMAIL),
  ]);

  if (sendResult && sendResult.type === dmpconnectRemoteActionConstants.DMPC_REMOTE_MSS_SEND_MESSAGE_REFUSED) {
    response = generateRemoteFailureResponse(requestId, {
      i_apiErrorType: errorTypes.RemoteErrors,
      i_apiErrorCode: 'REJECTED_BY_USER',
      s_apiErrorExtendedInformations: 'L’utilisateur a rejeté l’action demandée.',
    });
    yield call(updateToast, requestId, toast.TYPE.ERROR);
  } else if (sendResult && sendResult.type === dmpCommandSuccessContextualizedType(apiSections.MSS_SEND_SMTP_EMAIL)) {
    response = {
      '@RelatesTo': requestId,
      '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
    };
    if (sendResult.data.UniqueIds && sendResult.data.UniqueIds.length > 0) {
      response.uniqueIDs = sendResult.data.UniqueIds.join(';');
    }
    if (sendResult.data.DocumentsContent && sendResult.data.DocumentsContent.length > 0) {
      response.documentsCdaContent = rename(sendResult.data.DocumentsContent, key => key.replace(/^s_|^i_/, ''));
    }

    yield call(updateToast, requestId, toast.TYPE.SUCCESS);
  } else {
    const { data: error } = sendResult;

    response = generateRemoteFailureResponse(requestId, error);
    yield call(updateToast, requestId, toast.TYPE.ERROR);
  }

  yield put(clearSection(apiSections.MSS_SEND_SMTP_EMAIL));
  yield put(toggleLoadingBackdrop(false, ''));
  yield put(setShowMssPopup(false));
  yield put(resetMssEmailContent(false));
  yield call(sendResponse, response, responseChannel, channelUrl, type);
}

function* handleGetMssHpInfos(requestId, request, responseChannel, channelUrl, type) {
  const params = type === remoteFormats.JSON
    ? extractJSONGetMssInfosParams(request)
    : extractGetMssInfosParams(request);

  yield put(getAction(
    commands.getMssHpInfos,
    apiSections.MSS_GET_HP_INFOS,
    {
      ...formatGetMssHpInfosParams(params.name, params.given, params.rpps, params.specialty, params.organization),
      ...formatQuery(params),
    },
    {
      remote: true,
      subSection: `remote-${requestId}`,
    },
  ));

  let result;

  while (result === undefined) {
    const commandResult = yield take([
      dmpRemoteCommandSuccessContextualizedType(apiSections.MSS_GET_HP_INFOS),
      dmpRemoteCommandFailureContextualizedType(apiSections.MSS_GET_HP_INFOS),
    ]);

    const { context: { subSection } } = commandResult;
    if (subSection === `remote-${requestId}`) {
      result = commandResult;
    }
  }

  const {
    data: {
      PSList,
      i_queryLimitHappened,
      status,
      ...error
    }, type: resultType,
  } = result;

  let response;

  if (resultType === dmpRemoteCommandSuccessContextualizedType(apiSections.MSS_GET_HP_INFOS)) {
    response = {
      '@RelatesTo': requestId,
      '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
      results: rename(PSList, key => key.replace(/^s_/, '')),
      queryLimitHappened: i_queryLimitHappened,
    };
    yield call(updateToast, requestId, toast.TYPE.SUCCESS);
  } else {
    response = generateRemoteFailureResponse(requestId, error);
    yield call(updateToast, requestId, toast.TYPE.ERROR);
  }
  yield call(sendResponse, response, responseChannel, channelUrl, type);
}


function* handleLogout(requestId, responseChannel, channelUrl, type) {
  const response = {
    '@RelatesTo': requestId,
    '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
  };
  yield call(sendResponse, response, responseChannel, channelUrl, type);

  yield put(logout('/logout'));
}

function* handleConnectorRemoteOutExport(notification, notificationId) {
  const sessionId = yield select(getSessionId);

  console.log('outgoingMessage_connector', notification);
  yield put({
    type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
    command: {
      s_commandName: 'hl_sendRemoteOutMessage',
      s_sessionId: sessionId,
      Message: notification,
    },
    context: {
      section: 'sendRemoteOutMessage',
      subSection: notificationId,
    },
  });
  let success;

  while (success === undefined) {
    const result = yield take([
      dmpRemoteCommandSuccessContextualizedType('sendRemoteOutMessage'),
      dmpRemoteCommandFailureContextualizedType('sendRemoteOutMessage'),
    ]);
    const { context: { subSection }, type } = result;
    if (subSection === notificationId) {
      success = type === dmpRemoteCommandSuccessContextualizedType('sendRemoteOutMessage');
    }
  }
  return success;
}

function* handleEmailConnectorRemoteOutExport(notification, messageId, action) {
  const exportResult = yield call(handleConnectorRemoteOutExport, notification, messageId);

  yield put(remoteExportEmailDone(action.email));
  toast(
    exportResult
      ? 'Email exporté avec succès'
      : 'Erreur pendant l\'export de l\'email',
    {
      type: exportResult ? 'success' : 'error',
      position: 'top-right',
      closeOnClick: true,
      autoClose: true,
      hideProgressBar: true,
    },
  );
}
const getRemoteFormat = (state) => {
  const {
    dmpConnectPersistedAppConfiguration: {
      remoteFormat = remoteFormats.XML,
    },
  } = state;
  return remoteFormat;
};

export const handleExportDocument = function* (action) {
  const { uniqueUUid, ins } = action;
  const document = yield select(getDocumentInfoFromCache, ins, uniqueUUid);
  const cdaHeaders = yield select(getDocumentCdaHeaders, uniqueUUid);
  const cdaContent = yield select(getDocumentCdaContent, uniqueUUid);
  const content = yield select(getDocumentContentFromUniqueId, uniqueUUid);

  const { insExtension, insRoot } = ins.match(/^(?<insExtension>\w{15})(?<insRoot>[a-zA-Z]*)$/).groups;

  const author = document.Authors && document.Authors.length > 0 ? document.Authors[0] : {};
  const notificationId = generateUniqueId().toUpperCase();
  const notification = {
    notification: {
      '@data': 'DMPDocument',
      '@id': `{${notificationId}}`,
      patient: {
        ins: {
          '@extension': insExtension,
          '@root': insRoot || 'N',
        },
      },
      document: {
        uniqueId: document.s_uniqueId,
        uuid: document.s_uuid,
        content,
        htmlCdaHeaders: cdaHeaders,
        cdaContent,
        format: documentFormatNames[document.i_document_Format].toLowerCase(),
        typeCode: document.s_typeCode,
        title: document.s_title,
        description: document.s_description,
        invisiblePatient: isHiddenFor(document.i_document_Visibility, documentVisibilityStatuses.PATIENT_HIDDEN) ? 1 : 0,
        masquePs: isHiddenFor(document.i_document_Visibility, documentVisibilityStatuses.HEALTCARE_PROFESSIONAL_HIDDEN) ? 1 : 0,
        creationTime: document.s_creationDate,
        author: {
          '@name': author.s_hpName || '',
          '@given': author.s_hpGiven || '',
          '@institution': author.s_hpInstitution || '',
          '@profession': author.s_hpProfession || '',
        },
      },
    },
  };

  const exportChannel = yield select(getRemoteExportChannel);
  const exportChannelUrl = yield select(getRemoteExportChannelUrl);
  const remoteFormat = yield select(getRemoteFormat);

  if (exportChannel === remoteResponseChannels.CONNECTOR) {
    const exportResult = yield call(handleConnectorRemoteOutExport, notification, notificationId);
    toast(
      exportResult
        ? 'Document exporté avec succès'
        : 'Erreur pendant l\'export du document',
      {
        type: exportResult ? 'success' : 'error',
        position: 'top-right',
        closeOnClick: true,
        autoClose: true,
        hideProgressBar: true,
      },
    );
  } else {
    sendToOtherChannel(notification, exportChannel, exportChannelUrl, true, 'Document exporté avec succès', remoteFormat);
  }
};

const getIheXdmDetailsAndFormatAttachments = function* (attachments) {
  const formattedAttachments = [];

  for (let i = 0; i < attachments.length; i += 1) {
    const attachment = attachments[i];
    const {
      contentType, fileName, size, attachementB64,
    } = attachment;

    let iheXdmDetails;
    if (contentType.indexOf('application/zip') !== -1) {
      yield put(getAction(
        commands.getDocumentsFromIheXdm,
        apiSections.GET_DOCS_FROM_IHE_XDM,
        attachementB64,
        {
          subSection: fileName,
          silentError: true,
        },
      ));

      const iheXdmResults = yield take([
        dmpCommandSuccessContextualizedType(apiSections.GET_DOCS_FROM_IHE_XDM),
        dmpCommandFailureContextualizedType(apiSections.GET_DOCS_FROM_IHE_XDM),
      ]);
      if (iheXdmResults && iheXdmResults.type === dmpCommandSuccessContextualizedType(apiSections.GET_DOCS_FROM_IHE_XDM)) {
        const { data: { Documents = [] } } = iheXdmResults;
        const processedDocuments = Documents.map((doc) => {
          const { s_cdaContentInBase64 } = doc;
          const xml = b64DecodeUnicode(s_cdaContentInBase64);
          const {
            ClinicalDocument: {
              relatedDocument: {
                _attributes: { typeCode } = {},
                parentDocument: {
                  id: {
                    _attributes: {
                      root: replacedDocumentId,
                    } = {},
                  } = {},
                } = {},
              } = {},
            } = {},
          } = xml2js(xml, { compact: true });

          if (typeCode === 'RPLC') {
            return { ...doc, replacedDocumentUniqueId: replacedDocumentId };
          }

          return doc;
        });
        // supprimer les préfixes s_ et _i des clés
        iheXdmDetails = rename(processedDocuments, key => key.replace(/^s_|^i_/, ''));
      }
    }

    formattedAttachments.push({
      contentType,
      fileName,
      size: String(size),
      content: attachementB64,
      documents: iheXdmDetails,
    });
  }

  return formattedAttachments;
};

export const handleExportEmail = function* (action) {
  const {
    email: {
      mssEmail,
      attachments,
      fragment,
      body,
      messageId,
      headerMessageId,
      inReplyToMessageIds,
      references,
      replyTo,
      remoteExporting,
      date,
      flags,
      folderId,
      from,
      ...rest
    },
  } = action;
  try {
    const results = yield call(handleDownloadAllAttachments, attachments, messageId, mssEmail);
    const formattedAttachments = yield call(getIheXdmDetailsAndFormatAttachments, results);
    const notification = {
      notification: {
        '@data': 'email',
        '@id': `{${generateUniqueId().toUpperCase()}}`,
        email: {
          ...rest,
          ...Object.entries(flags).reduce((result, [key, value]) => ({ ...result, [`@${key}`]: String(value) }), {}),
          from: from[0],
          replyTo,
          folderId: String(folderId),
          date: String(date),
          // attachments: results.map((att) => {
          //   const {
          //     contentType, fileName, size, attachementB64,
          //   } = att;
          //   return {
          //     contentType,
          //     fileName,
          //     size: String(size),
          //     content: attachementB64,
          //   };
          // }),
          attachments: formattedAttachments,
          fragment: fragment ? b64EncodeUnicode(fragment) : undefined,
          body: b64EncodeUnicode(body),
          messageId: headerMessageId || messageId,
          inReplyToMessageIds: inReplyToMessageIds ? inReplyToMessageIds.join(';') : undefined,
          references: references ? references.join(';') : undefined,
        },
      },
    };

    const exportChannel = yield select(getRemoteExportChannel);
    const exportChannelUrl = yield select(getRemoteExportChannelUrl);
    const remoteFormat = yield select(getRemoteFormat);

    if (exportChannel === remoteResponseChannels.CONNECTOR) {
      yield call(handleEmailConnectorRemoteOutExport, notification, messageId, action);
    } else {
      sendToOtherChannel(notification, exportChannel, exportChannelUrl, true, 'Email exporté avec succès', remoteFormat);
      yield put(remoteExportEmailDone(action.email));
    }
  } catch (e) {
    yield put(remoteExportEmailDone(action.email));
  }
};

export const handleExportVitaleData = function* (action) {
  const remoteControlActive = yield select(isRemoteControlActive);
  const remoteExportChannel = yield select(getRemoteExportChannel);
  const remoteExportChannelUrl = yield select(getRemoteExportChannelUrl);
  const remoteExportVitaleData = yield select(getRemoteExportVitaleData);
  const remoteControlDisableExports = yield select(getRemoteDisableExports);
  const remoteFormat = yield select(getRemoteFormat);

  if (
    !remoteControlDisableExports
    && (
      remoteControlActive
      || [remoteResponseChannels.IFRAME, remoteResponseChannels.URL].includes(remoteExportChannel)
    )
    && remoteExportVitaleData
  ) {
    const { type, data: { s_status, ...data } } = action;
    const dataType = type === dmpCommandSuccessContextualizedType(apiSections.GET_APCV_CONTEXT) ? 'ApCV' : 'vitaleCard';
    const notification = {
      notification: {
        '@data': dataType,
        '@id': `{${generateUniqueId().toUpperCase()}}`,
        [dataType]: data,
      },
    };

    if (remoteExportChannel === remoteResponseChannels.CONNECTOR) {
      const sessionId = yield select(getSessionId);
      console.log('outgoingMessage_connector', notification);
      yield put({
        type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
        command: {
          s_commandName: 'hl_sendRemoteOutMessage',
          s_sessionId: sessionId,
          Message: notification,
        },
        context: {
          section: 'sendRemoteOutMessage',
          subSection: dataType,
        },
      });
    } else {
      sendToOtherChannel(notification, remoteExportChannel, remoteExportChannelUrl, false, '', remoteFormat);
    }
  }
};


export const handleExportVitaleCardData = function* (action, vitaleXml) {
  // const vitaleXml = yield call(getVitaleCardXmlContent);
  if (vitaleXml) {
    yield call(handleExportVitaleData, {
      ...action,
      data: {
        ...action.data,
        rawData: vitaleXml,
      },
    });
  }
};

export const handleExportCpxData = function* (cards) {
  const remoteControlActive = yield select(isRemoteControlActive);
  const remoteExportChannel = yield select(getRemoteExportChannel);
  const remoteExportChannelUrl = yield select(getRemoteExportChannelUrl);
  const remoteControlDisableExports = yield select(getRemoteDisableExports);
  const remoteFormat = yield select(getRemoteFormat);

  if (
    !remoteControlDisableExports
      && (
        remoteControlActive
          || [remoteResponseChannels.IFRAME, remoteResponseChannels.URL].includes(remoteExportChannel)
      )
      && Number(env.REACT_APP_REMOTE_CONTROL_AUTO_EXPORT_CPX_DATA) === 1
  ) {
    const notification = {
      notification: {
        '@data': 'CPX',
        '@id': `{${generateUniqueId().toUpperCase()}}`,
        cards,
      },
    };

    if (remoteExportChannel === remoteResponseChannels.CONNECTOR) {
      const sessionId = yield select(getSessionId);
      console.log('outgoingMessage_connector', notification);
      yield put({
        type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
        command: {
          s_commandName: 'hl_sendRemoteOutMessage',
          s_sessionId: sessionId,
          Message: notification,
        },
        context: {
          section: 'sendRemoteOutMessage',
          subSection: 'cpx',
        },
      });
    } else {
      sendToOtherChannel(notification, remoteExportChannel, remoteExportChannelUrl, false, '', remoteFormat);
    }
  }
};

const getMssClientMode = ({ dmpConnectPersistedAppConfiguration: { mssClientMode } = {} }) => mssClientMode;

function* receiveRemoteRequest({ data, source }) {
  const isLoggedIn = yield select(isUserLoggedInAccessor);
  const showNotifications = yield select(getShowNotifications);
  const mssClientMode = yield select(getMssClientMode);
  const {
    message: {
      RemoteCommands = [],
      s_apiErrorExtendedInformations = '',
      s_status = '',
      i_apiErrorType = 0,
      i_apiErrorCode = 0,
    },
  } = data;

  if (s_status.toLowerCase() === 'error') {
    if (s_apiErrorExtendedInformations === "Unknown command: 'hl_startRemoteControlInMonitoring'") {
      yield put(stopRemoteControl());
      yield put(setGlobalConfiguration('forceDesactivateRemoteControl', true));
    } else if (!(i_apiErrorType === errorTypes.SoftwareErrors && i_apiErrorCode === softwareErrors.WEBSOCKET_TIMEOUT)) {
      const details = createErrorDetails('Erreur détaillée', { detailsType: 'error', ...data.message });

      const modalError = createModalError({ ...data.message, i_apiErrorType: errorTypes.RemoteErrors }, [details]);
      yield put(setModalError(modalError));
    }
    // toast.error('Mode pilotable désactivé');
  } else {
    yield put(setGlobalConfiguration('forceDesactivateRemoteControl', false));

    // did we receive a request ?
    for (let i = 0; i < RemoteCommands.length; i += 1) {
      const { request, request: { type = remoteFormats.XML } } = RemoteCommands[i];

      let requestId;
      let action;
      let confidentialityLevel;
      let channel;
      let channelUrl;
      let requestRest;

      if (type === remoteFormats.JSON) {
        ({
          id: requestId,
          action,
          confidentialityLevel = 0,
          responseChannel: channel,
          responseChannelUrl: channelUrl = undefined,
          ...requestRest
        } = request);
      } else {
        ({
          '@id': requestId,
          '@action': action,
          '@confidentialityLevel': confidentialityLevel = 0,
          '@responseChannel': channel,
          '@responseChannelUrl': channelUrl = undefined,
          ...requestRest
        } = request);
      }


      const responseChannel = source === remoteSources.CONNECTOR ? remoteResponseChannels.CONNECTOR : channel;

      if (showNotifications === true && remoteActions.logout) {
        toast(
          `requete pilotage reçue : ${action} (${requestId})`,
          {
            toastId: requestId,
            autoClose: false,
          },
        );
      }

      if (
        ![
          remoteActions.sendMssMessage,
          remoteActions.getStatus,
          remoteActions.getEfficienceVersion,
          remoteActions.getMssHpInfos,
          remoteActions.logout,
        ].includes(action)
        && mssClientMode === true
      ) {
        yield call(handleRefusedActionError, requestId, responseChannel, channelUrl, type);
      } else {
        try {
          const categories = yield select(getInteropCodesFromState, 'categories');
          const healthcareSettings = yield select(getInteropCodesFromState, 'healthcareSettings');
          const professions = yield select(getInteropCodesFromState, 'professions');
          const specialties = yield select(getInteropCodesFromState, 'physicianSpecialities');
          const mssApiType = yield select(({ dmpconnectMSSConfiguration }) => dmpconnectMSSConfiguration.mssApiType);

          validateCommand(request, categories, healthcareSettings, professions, specialties, mssApiType, type);

          if (!isLoggedIn && ![remoteActions.getEfficienceVersion, remoteActions.getStatus, remoteActions.logout].includes(action)) {
            yield call(handleLoggedInError, requestId, responseChannel, channelUrl, type);
          } else {
            switch (action) {
              case remoteActions.getCertifiedIdentity:
                yield call(
                  handleGetCertifiedIdentity,
                  requestId,
                  [true, 'true', '1', 1].includes(confidentialityLevel) ? 1 : 0,
                  requestRest,
                  responseChannel,
                  channelUrl,
                  type,
                );
                break;
              case remoteActions.getCurrentDmp:
                yield call(handleGetCurrentDMPRemoteRequest, requestId, responseChannel, channelUrl, type);
                break;
              case remoteActions.findDmp:
                yield call(handleFindDMPRemoteRequest, requestId, requestRest, responseChannel, channelUrl, type);
                break;
              case remoteActions.testDmpExistence:
                yield call(
                  handleTestDmpExistenceRemoteRequest,
                  requestId,
                  [true, 'true', '1', 1].includes(confidentialityLevel) ? 1 : 0,
                  requestRest,
                  responseChannel,
                  channelUrl,
                  type,
                );
                break;
              case remoteActions.openDmp:
              case 'openOrCreateDmp':
                yield call(
                  handleOpenDmp,
                  requestId,
                  [true, 'true', '1', 1].includes(confidentialityLevel) ? 1 : 0,
                  requestRest,
                  responseChannel,
                  channelUrl,
                  type,
                );
                break;
              case remoteActions.submitDocument:
                yield call(
                  handleSubmitDocument,
                  requestId,
                  [true, 'true', '1', 1].includes(confidentialityLevel) ? 1 : 0,
                  requestRest,
                  responseChannel,
                  channelUrl,
                  type,
                );
                break;
              case remoteActions.deleteDocument:
                yield call(
                  handleDeleteDocument,
                  requestId,
                  [true, 'true', '1', 1].includes(confidentialityLevel) ? 1 : 0,
                  requestRest,
                  responseChannel,
                  channelUrl,
                  type,
                );
                break;
              case remoteActions.closeDmpSession:
                yield call(handleCloseDmpSession, requestId, responseChannel, channelUrl, type);
                break;
              case remoteActions.getEfficienceVersion:
                yield call(handleGetEfficienceVersion, requestId, responseChannel, channelUrl, type);
                break;
              case remoteActions.getStatus:
                yield call(handleGetStatus, requestId, responseChannel, channelUrl, type);
                break;
              case remoteActions.sendMssMessage:
                yield call(handleSendMssMessage, requestId, requestRest, responseChannel, channelUrl, type);
                break;
              case remoteActions.getMssNbUnreadMessages:
                yield call(handleGetMssNbUnreadMessages, requestId, requestRest, responseChannel, channelUrl, type);
                break;
              case remoteActions.getMssHpInfos:
                yield call(handleGetMssHpInfos, requestId, requestRest, responseChannel, channelUrl, type);
                break;
              case remoteActions.logout:
                yield call(handleLogout, requestId, responseChannel, channelUrl, type);
                break;
              default:
                yield call(handleUnknownActionError, requestId, responseChannel, channelUrl, type);
            }
          }
        } catch (e) {
          console.log(e);
          let response;
          if (e instanceof ValidationError) {
            response = generateRemoteFailureResponse(
              requestId,
              {
                i_apiErrorType: errorTypes.RemoteErrors,
                i_apiErrorCode: 'INVALID_FORMAT',
                s_apiErrorExtendedInformations: `${type} invalide`,
              },
              e.inner.map(error => ({
                field: error.path ? error.path.replace('.value', '') : undefined,
                value: error.path ? error.value : undefined,
                error: error.message,
              })),
            );
          } else {
            response = generateRemoteFailureResponse(
              requestId,
              {
                i_apiErrorType: errorTypes.RemoteErrors,
                i_apiErrorCode: 'INVALID_FORMAT',
                s_apiErrorExtendedInformations: e.message,
              },
            );
          }

          yield call(sendResponse, response, responseChannel, channelUrl, type);
          yield call(updateToast, requestId, toast.TYPE.ERROR);
        }
      }
    }
  }
}

export const handleReceivedRemoteRequest = function* () {
  const requestChannel = yield actionChannel(dmpconnectRemoteActionConstants.DMPC_REMOTE_MONITORING_RECEIVED_REQUEST);
  while (true) {
    const action = yield take(requestChannel);
    yield call(receiveRemoteRequest, action);
  }
};

/**
 * Manage monitoring update
 * @param socketChannel
 * @param updateType
 * @returns {IterableIterator<CallEffect|TakeEffect|PutEffect<{data, context, type, command}>>}
 */
function* receivingRemoteRequest(socketChannel, updateType) {
  while (true) {
    const payload = yield take(socketChannel);
    const { message: { RemoteCommands = [] } = {} } = payload;
    if (RemoteCommands.length > 0) {
      console.log('incomingMessage_connector', payload);
    }
    yield put({
      type: updateType,
      data: payload,
      source: remoteSources.CONNECTOR,
    });
  }
}

export const handleRemoteMonitoring = function* () {
  const monitoringAction = {
    startAction: dmpconnectRemoteActionConstants.DMPC_REMOTE_MONITORING_START,
    stopAction: dmpconnectRemoteActionConstants.DMPC_REMOTE_MONITORING_STOP,
    updateAction: dmpconnectRemoteActionConstants.DMPC_REMOTE_MONITORING_RECEIVED_REQUEST,
  };


  while (true) {
    const { command } = yield take(monitoringAction.startAction);
    const remoteControlActive = yield select(isRemoteControlActive);

    if (remoteControlActive) {
      const socketChannel = yield call(websocketChannel, command, null, true);

      const { cancel } = yield race({
        task: call(
          receivingRemoteRequest,
          socketChannel,
          monitoringAction.updateAction,
        ),
        cancel: take(monitoringAction.stopAction),
      });
      if (cancel) {
        socketChannel.close();
      }
    }
  }
};
