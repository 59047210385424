import moment from 'moment';
import { documentStatuses } from 'dmpconnectjsapp-base/helpers/findDocuments';
import { calculateVisibility, canConsultDocument } from '../../rules/documentRules';

export const documentStatusesLabel = {
  [documentStatuses.ALL]: 'Tous',
  [documentStatuses.APPROVED]: 'Approuvé',
  [documentStatuses.DEPRECATED]: 'Obsolète',
  [documentStatuses.ARCHIVED]: 'Archivé',
};

export const findDocumentByUniqueUUid = (documents, uniqueUUid) => documents[uniqueUUid] || {};
export const findDocumentByUniqueId = (documents, uniqueId, exeptUuid = null) => {
  const docArray = Object.values(documents);
  return docArray.filter(document => document.s_uniqueId === uniqueId && (!exeptUuid || (exeptUuid && document.s_uuid !== exeptUuid)));
};
export const findNewDocuments = (documents) => {
  const docArray = Object.values(documents);
  return docArray.filter(document => document.isNew === true);
};

export const getDocumentData = (documentContent) => {
  if (documentContent && documentContent.s_status === 'OK') {
    return documentContent.s_fileContentInBase64;
  }
  return null;
};
export const getCdaContent = (documentContent) => {
  if (documentContent && documentContent.s_status === 'OK') {
    return documentContent.s_cdaContentInBase64;
  }
  return null;
};

export const getCdaHeaders = (documentContent) => {
  if (documentContent && documentContent.s_status === 'OK') {
    return documentContent.s_cdaHeadersInBase64;
  }
  return null;
};

export const isMetaCategorySelected = (selectedCategories, metaCategory) => {
  if (!metaCategory || !selectedCategories) return false;

  const { types: childrenCategories } = metaCategory;

  return childrenCategories.some(category => selectedCategories.includes(category.code));
};

export const getFilteredMetaCategoriesIndex = (metaCategories, selectedCategories) => {
  const selectedMetas = [];
  if (!metaCategories) return selectedMetas;
  metaCategories.forEach((meta, index) => {
    if (isMetaCategorySelected(selectedCategories, meta)) {
      selectedMetas.push(index);
    }
  });
  return selectedMetas;
};

export const isMetaCategoryFullyFiltered = (selectedCategories, metaCategory) => {
  if (!metaCategory || !selectedCategories) return false;

  const { types: childrenCategories = [] } = metaCategory;

  return childrenCategories.every(category => selectedCategories.includes(category.code));
};

export const getFullyFilteredMetaCategoriesIndex = (metaCategories, selectedCategories) => {
  const selectedMetas = [];
  if (!metaCategories) return selectedMetas;
  metaCategories.forEach((meta, index) => {
    if (isMetaCategoryFullyFiltered(selectedCategories, meta)) {
      selectedMetas.push(index);
    }
  });
  return selectedMetas;
};

const formatDate = (date) => {
  let completeDate = date;
  const { groups: { day, month, year } } = date.match(/(?<day>[0-9]{2})\/(?<month>[0-9]{2})\/(?<year>[0-9]{4})(?<hour>.*)/);
  if (day === '00' && month === '00') completeDate = `01/01/${year}00:00:00`;
  else if (day === '00') completeDate = `01/${month}/${year}00:00:00`;
  return moment(completeDate, 'DD/MM/YYYYHH:mm:ss');
};
const getDateFilter = (columnName, startDate, startDateHour, endDate, endDateHour) => ({
  id: columnName,
  type: 'date',
  datemin: startDate
    ? formatDate(startDate + startDateHour)
    : moment(0),
  datemax: endDate
    ? formatDate(endDate + endDateHour)
    : moment(),
});

export const findDocumentParamsToFilters = (params) => {
  const {
    status,
    submissionStartDate,
    submissionStartDateHour,
    submissionEndDate,
    submissionEndDateHour,
    creationStartDate,
    creationStartDateHour,
    creationEndDate,
    creationEndDateHour,
    actStartDate,
    actStartDateHour,
    actEndDate,
    actEndDateHour,
    patientHidden,
    guardianHidden,
    healthcareProfessionalHidden,
    categories,
    title,
    author,
    specialty,
  } = params;

  const filters = [];

  filters.push({
    id: 'i_document_Visibility',
    type: 'value',
    value: calculateVisibility(patientHidden, healthcareProfessionalHidden, guardianHidden),
  });
  filters.push({ id: 's_typeCode', type: 'array', array: categories });
  if (creationStartDate || creationEndDate) {
    filters.push(getDateFilter('s_creationDate',
      creationStartDate,
      creationStartDateHour,
      creationEndDate,
      creationEndDateHour));
  }
  if (actStartDate || actEndDate) {
    filters.push(getDateFilter('s_serviceStartDate',
      actStartDate,
      actStartDateHour,
      actEndDate,
      actEndDateHour));
  }
  if (submissionStartDate || submissionEndDate) {
    filters.push(
      getDateFilter('s_submissionDate',
        submissionStartDate,
        submissionStartDateHour,
        submissionEndDate,
        submissionEndDateHour),
    );
  }
  filters.push({ id: 'i_document_Status', type: 'value', value: status });
  filters.push({ id: 's_title', type: 'string', value: title });
  filters.push({
    id: 'Authors', type: 'authors', value: author,
  });
  filters.push({
    id: 'specialty', type: 'specialty', value: specialty,
  });

  return filters;
};

export const filterDocument = (filter, document) => {
  const {
    id, value = null, datemin = null, datemax = null, array, type,
  } = filter;

  if (type === 'array') {
    return !array || array.includes(document[id]);
  }
  if (type === 'value' && value && value !== 0) {
    return document[id] === value;
  }
  if (type === 'date' && (datemin || datemax)) {
    const momentValue = moment.utc(document[id], 'YYYYMMDDHHmmss');
    return momentValue.isBetween(datemin, datemax, null, '[]');
  }
  if (type === 'string' && value) {
    return document[id].toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }
  if (type === 'authors' && value) {
    return document.Authors.findIndex((author) => {
      const authorName = author.s_hpGiven.concat(' ').concat(author.s_hpName).toLowerCase();
      return authorName.indexOf(value.toLowerCase()) !== -1;
    }) !== -1;
  }
  if (type === 'specialty' && value) {
    return document.Authors.some(author => author.s_hpSpeciality === value);
  }

  return true;
};

export const getCategoryCounts = (metaCategories, documents) => {
  if (documents === undefined) return metaCategories;
  const categoryCount = Object.values(documents).reduce((acc, curr) => {
    acc[curr.s_typeCode] = acc[curr.s_typeCode] || { count: 0, new: 0 };
    acc[curr.s_typeCode].count += 1;
    if (curr.isNew === true) acc[curr.s_typeCode].new += 1;
    return acc;
  }, {});

  const metaCategoriesCount = [];

  metaCategories.forEach((meta, index) => {
    // JSON.parse(JSON.stringify(meta)) pour copier l'objet sans référence
    metaCategoriesCount[index] = { ...JSON.parse(JSON.stringify(meta)), count: 0, new: 0 };

    meta.types.forEach((category, indexCat) => {
      const count = categoryCount[category.code] || { count: 0, new: 0 };
      metaCategoriesCount[index].types[indexCat] = { ...category, ...count };
      metaCategoriesCount[index].count += count.count;
      metaCategoriesCount[index].new += count.new;
    });
  });

  return metaCategoriesCount;
};

export const getFilteredDocuments = (documents, params, accessRights) => {
  const filters = findDocumentParamsToFilters(params);
  return Object.values(documents || {}).filter(document => (
    canConsultDocument(document, accessRights)
    && filters.every(filter => filterDocument(filter, document))
  ));
};
export const getFindDocumentsParams = (state) => {
  const { dmpconnectUser: { findDocumentsParams: params } } = state;
  return { ...params };
};

export const getFindDocumentsLastSearchTimeStamp = (state) => {
  const {
    dmpconnect: {
      selectedIns: ins,
    },
    dmpconnectDocumentsCache: {
      [ins]: {
        timestamp: lastSearchTimestamp = null,
      } = {},
    },
  } = state;
  return lastSearchTimestamp;
};
