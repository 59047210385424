import merge from 'lodash.merge';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { dmpCommandSuccessContextualizedType } from 'dmpconnectjsapp-base/actions';
import {
  dmpconnectConfigurationActionConstants,
  dmpconnectUserActionConstants,
} from '../constants';
import env from '../../envVariables';
import { remoteFormats } from '../helpers/remote';

const initialStateFromEnv = {
  forceLoginCheck: Number(env.REACT_APP_ES_LOGIN_CHECK) === 1,
  remoteControlAppName: env.REACT_APP_REMOTE_CONTROL_APP_NAME || 'le mode pilotable',
  hideRemoteControlNotifications: Number(env.REACT_APP_HIDE_REMOTE_CONTROL_NOTIFICATIONS) === 1,
  remoteControlDisableExports: Number(env.REACT_APP_REMOTE_CONTROL_DISABLE_EXPORTS) === 1,
  remoteExportVitaleData: Number(env.REACT_APP_REMOTE_CONTROL_AUTO_EXPORT_VITALE_DATA) === 1,
  remoteExportChannel: env.REACT_APP_REMOTE_CONTROL_EXPORT_CHANNEL,
  remoteExportChannelUrl: env.REACT_APP_REMOTE_CONTROL_EXPORT_CHANNEL_URL,
  remoteFormat: env.REACT_APP_REMOTE_CONTROL_FORMAT || remoteFormats.XML,
  mockApiLogin: Number(env.REACT_APP_API_LOGIN_MOCK) === 1,
  showAirStats: Number(env.REACT_APP_SHOW_AIR_STATS) === 1,
  insiVitaleWS2: Number(env.REACT_APP_INSI_WS2_OVERIDE) === 1,
  saasToken: env.REACT_APP_SAAS_TOKEN || null,
  efficienceDP: Number(env.REACT_APP_DP_MODE) === 1,
  showDocCategoryInList: Number(env.REACT_APP_SHOW_DOC_CAT_IN_LIST) === 1,
  cpxLoginType: env.REACT_APP_CPX_LOGIN_TYPE || 'CPX',
  vitaleAutoRead: Number(env.REACT_APP_VITALE_AUTO_READ) === 1,
  hideConfig: Number(env.REACT_APP_HIDE_CONFIG) === 1,
  hideMailiz: Number(env.REACT_APP_HIDE_MAILIZ) === 1,
  hideHeader: Number(env.REACT_APP_HIDE_HEADER) === 1,
  hidePsSidebar: Number(env.REACT_APP_HIDE_PS_SIDEBAR) === 1,
  exportOnlyUI: Number(env.REACT_APP_AIR_SET_EXPORTONLY_UI) === 1, // Config spéciale Toulouse
  noDashboard: Number(env.REACT_APP_DEACTIVATE_DASHBOARD) === 1,
  hideNewsSidebar: Number(env.REACT_APP_HIDE_NEWS_SIDEBAR) === 1,
  openIdRedirectURI: env.REACT_APP_ES_LOGIN_OPENID_REDIRECTION_URL || null,
  forceSchematronsValidation: Number(env.REACT_APP_FORCE_SCHEMATRONS_VALIDATION) === 1,
  ignorePdfA1Transparency: Number(env.REACT_APP_PDFA1_CONVERSION_IGNORE_TRANSPARENCY) === 1,
  disabledPdfA1Conversion: Number(env.REACT_APP_PDFA1_CONVERSION_DISABLE) === 1,
  missingConnectorErrorMessage: env.REACT_APP_MISSING_CONNECTOR_ERROR_MESSAGE,
  missingConnectorErrorMessageIsBase64: Number(env.REACT_APP_MISSING_CONNECTOR_ERROR_MESSAGE_IS_BASE64) === 1,
  mssClientMode: Number(env.REACT_APP_MSS_CLIENT_MODE) === 1,

  vidalAppKey: env.REACT_APP_VIDAL_APP_KEY,
  vidalAppId: env.REACT_APP_VIDAL_APP_ID,
  vidalUrl: env.REACT_APP_VIDAL_API,
  vidalActive: Number(env.REACT_APP_VIDAL) === 1,

  dmpConnectLogLevel: Number(env.REACT_APP_DEFAULT_DMPC_LOG_LEVEL),
  dmpConnectJsLogLevel: Number(env.REACT_APP_DEFAULT_DMPCJS_LOG_LEVEL),
  enableCustomPracticeLocation: Number(env.REACT_APP_ENABLE_CUSTOM_PRACTICE_SETTING) === 1,

  isMobileLayout: Number(env.REACT_APP_MOBILE) === 1,
};

const initialState = {

  logincheck: {},
  apiLoginValues: {
    psInternalId: null,
    patientInternalId: null,
  },

  mssAuthenticationId: '',

  validatedSaasTokens: [],
  cpxLoginToken: null,
  envCheckSum: null,

  ...initialStateFromEnv,
};


export function dmpConnectPersistedAppConfiguration(state = initialState, action) {
  switch (action.type) {
    case dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_CONFIGURATION:
      return merge({}, state, { [action.key]: action.value });
    case dmpconnectConfigurationActionConstants.DMPC_RESET_PERSIST_APP_CONFIGURATION_FROM_ENV:
      return merge({}, state, initialStateFromEnv);
    case dmpconnectUserActionConstants.DMPC_ES_LOGIN_SUCCESS:
    case dmpconnectUserActionConstants.DMPC_LOGIN_SUCCESS:
      return merge({}, state, { logincheck: null, apiLoginValues: initialState.apiLoginValues, mssAuthenticationId: '' });
    case dmpconnectUserActionConstants.DMPC_LOGOUT_SUCCESS:
      return merge({}, state, { mssAuthenticationId: '' });
    case dmpCommandSuccessContextualizedType(apiSections.SET_LOG_LEVELS):
      return merge({}, state, {
        dmpConnectLogLevel: action.command.i_dmpConnectLogLevel,
        dmpConnectJsLogLevel: action.command.i_dmpConnectJsLogLevel,
      });
    default:
      return state;
  }
}
