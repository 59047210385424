import merge from 'lodash.merge';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { formatGetAccessibleDMPListParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { getConfigurationValue, isAirActive } from 'dmpconnectjsapp-base/helpers/accessors';
import { getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import moment from 'moment';
import { getAction } from '../../actions';
import { deduplicatePatientsList } from '../../reducers';

export const getFullName = (name = '', given = '', birthName = '') => {
  let fullName = `${name || birthName} ${given}`;
  if (birthName !== '' && name !== '' && birthName.toLowerCase() !== name.toLowerCase()) {
    fullName += `, né(e) ${birthName}`;
  }
  return fullName;
};

export const callGetAccessibleDmpList = (air, psId, esId, dispatch, filterMode, filterDate, saveResultIds = false) => {
  // appel normal
  dispatch(
    getAction(
      commands.getAccessibleDMPList,
      apiSections.ACCESSIBLE_DMP_LIST_SECTION,
      formatGetAccessibleDMPListParams({ type: filterMode, date: filterDate }),
      {
        subSection: psId,
        silentError: true,
        contextParams: { type: filterMode, date: filterDate, saveResultIds },
      },
    ),
  );

  // si air on fait une deuxieme requete pour l'établissement
  if (air) {
    dispatch(
      getAction(
        commands.getAccessibleDMPList,
        apiSections.ACCESSIBLE_DMP_LIST_SECTION,
        formatGetAccessibleDMPListParams({ type: filterMode, date: filterDate }),
        {
          subSection: esId,
          forceEs: true,
          silentError: true,
          contextParams: { type: filterMode, date: filterDate },
        },
      ),
    );
  }
};

const arrayToKeyedList = (array, key) => array.reduce((obj, item) => ({ [item[key]]: item, ...obj }), {});

export const getAccessibleDMPListCache = (cache, psId, esId, availableDmpAllowed) => {
  const { [psId]: psSection = {}, [esId]: esSection = {} } = cache;
  const {
    AccessibleDmps: psCache = [],
    timestamp: psTimestamp = null,
    lastResultIds: psLastResultIds = [],
  } = psSection;

  const {
    AccessibleDmps: esCache = [],
    timestamp: esTimestamp = null,
    lastResultIds: esLastResultIds = [],
  } = esSection;

  let timestamp = psTimestamp;
  const lastResultIds = psLastResultIds;

  if (!availableDmpAllowed) timestamp = moment().unix();
  // si la td0.4 est autorisée et un des caches est vide, on lance la td04
  if ((!timestamp || (esId && !esTimestamp))) return {};

  if (esId) {
    const mergedCache = merge(
      {},
      arrayToKeyedList(psCache.map(c => ({ ...c, consult: true })), 's_ins'),
      arrayToKeyedList(esCache.map(c => ({ ...c, admin: true })), 's_ins'),
    );

    return {
      AccessibleDmps: deduplicatePatientsList(Object.values(mergedCache)),
      timestamp: timestamp < esTimestamp ? timestamp : esTimestamp,
      showAccessTypeColumns: true,
      lastResultIds: [...psLastResultIds, ...esLastResultIds],
    };
  }

  return {
    AccessibleDmps: deduplicatePatientsList(psCache),
    timestamp,
    lastResultIds,
  };
};

export const getAccessibleDMPCount = (state) => {
  const { accessRights } = getAccessRightsProps(state);
  const {
    dmpconnectAccessibleDmpList: accessibleDMPListCache,
    dmpconnectConnectorConfig,
  } = state;
  const air = isAirActive(state);
  const airOnly = getConfigurationValue('airOnly', dmpconnectConnectorConfig);
  const cache = getAccessibleDMPListCache(
    accessibleDMPListCache,
    accessRights.psId,
    air && !airOnly ? accessRights.esId : null,
  );

  return cache.AccessibleDmps ? cache.AccessibleDmps.length : 0;
};

export const getPatientInsiIdentityFromAccessibleDMPsCache = (state, ins) => {
  const { accessRights } = getAccessRightsProps(state);
  const {
    dmpconnectAccessibleDmpList: accessibleDMPListCache,
    dmpconnectConnectorConfig,
  } = state;
  const air = isAirActive(state);
  const airOnly = getConfigurationValue('airOnly', dmpconnectConnectorConfig);
  const cache = getAccessibleDMPListCache(
    accessibleDMPListCache,
    accessRights.psId,
    air && !airOnly ? accessRights.esId : null,
  );
  const { AccessibleDmps = [] } = cache;
  const dmp = AccessibleDmps.find(p => p.s_ins === ins);

  return dmp ? dmp.insiIdentity : undefined;
};
